/*
 * @PACKAGE foo-text-buttons
 *
 * TABLE OF CONTENT:
 * ----------------
 * 1. Loop main theme colors, check $foo-main-theme-colors in (_foo-colors-map.scss)
 * 2. Add Button/Text Classes in the loop to set dynmic classes of main theme colors
 * 3. Add Custom classes related button/text
 *
 * Buttons Classes Example:
 * -----------------------
 * .foo-btn-primary
 * .foo-btn-secondary
 * .
 *
 * Text Classes Example:
 * --------------------
 * .foo-text-primary
 * .foo-text-secondary
 * .
 * .
 * .
 */

/* * ----------------------------------------------------------------------------- * *
 * * 1. Loop main theme colors, check $foo-main-theme-colors in (_foo-colors-map.scss) * *
 * * ----------------------------------------------------------------------------- * */
@each $key,$color in $foo-main-theme-colors {
  /* * --------------------------------------------------------------------------------- * *
   * * 2. Add Button/Text Classes in the loop to set dynmic classes of main theme colors * *
   * * --------------------------------------------------------------------------------- * */
  .foo-btn-#{$key} {
    background: var(--foo-#{$key}-color) !important;
    color: var(--foo-white-color) !important;
    border: 0 !important;
    border-radius: 10px !important;
    padding: 0px 20px !important;
    width: max-content;
    min-width: 175px !important;
    min-height: 40px !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        background: var(--foo-#{$key}-color) !important;
        color: var(--foo-white-color) !important;
        box-shadow: 0px 10px 10px -4px rgba($color, 0.4) !important;
      }
    }
  }

  .foo-btn-outline-#{$key} {
    background: var(--foo-white-color) !important;
    color: var(--foo-#{$key}-color) !important;
    border: 1px solid var(--foo-#{$key}-color) !important;
    border-radius: 10px !important;
    min-width: 175px !important;
    width: max-content !important;
    height: 40px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    display: inline-flex !important;
    justify-content: center !important;
    flex-direction: row !important;
    align-items: center !important;
    outline: 0 !important;
    padding: 0 15px !important;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        background: var(--foo-white-color) !important;
        color: var(--foo-#{$key}-color) !important;
        box-shadow: 0px 19px 10px -14px rgba($color, 0.2) !important;
      }
    }
  }

  .foo-text-#{$key} {
    color: var(--foo-#{$key}-color) !important;
    * {
      color: var(--foo-#{$key}-color) !important;
    }
  }
}

/* * ----------------------------------------- * *
 * * 3. Add Custom classes related button/text * *
 * * ----------------------------------------- * */
.foo-btn-center {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}

.foo-btn-listing-sm {
  height: -moz-fit-content !important;
  height: fit-content !important;
  min-width: 50px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  border-radius: 4px !important;
  padding: 3px 13px !important;
}

.foo-btn-no-shadow{
  box-shadow: none!important;
}

.foo-btn-icon {
  width: 100% !Important;
  min-width: unset !important;
  padding: 0px !important;
  opacity: 1!important;
}

.foo-text-no-overflow {
  overflow: hidden;
  * {
    overflow: hidden;
  }
  .foo-text-ellipsis {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: normal;
  }
}


@each $key, $color in $foo-theme-colors {
  .foo-shadow-#{$key} {
    box-shadow: 0px 6px 19px var(--foo-#{$key}-opacity-2);
  }
  .foo-bg-#{$key} {
    background: var(--foo-#{$key}-bg);
  }
}

@for $i from 1 through $foo-sizes {
  .foo-w-#{$i} {
    width: $i + px!important;
  }
  .foo-h-#{$i} {
    height: $i + px!important;
  }
}

$i: 8;
@while $i <= 50 {
  .foo-fs-#{$i} {
    font-size: #{$i}px !important;
  }
  $i: $i + 2;
}

@media (max-width: 540px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

@media screen and (min-width: 1025px){
  .full-width-submit-container{
    left: 250px !important;
  }
}

@media screen and (max-width: 768px){

  .nav-pills li a {
    margin: 10px 2px 20px;
  }
  .search-container input,
  .search-container ng-select{
    // margin-bottom:20px!important;
  }
  .money-separator{
    margin-top:10px!important;
    margin-bottom:10px!important;
  }
  .page-content-wrapper{
    margin-top:62px !important;
    padding: 20px 22px !important;
  }
  .page-body.direction-rtl {
    .page-content-wrapper {
      margin-right: 0px !important;
      margin-left: 0px !important;
      &.menuclosed{
        margin-right: 0px !important;
      }
    }
    app-header nav.top-nav {
      left: 0 !important;
      right: 0px !important;
    }
    app-header nav.top-nav.menuclosed {
      left: 0 !important;
      right: 0px !important;
      width:100%;
    }
    app-left-menu .navbar-nav{
      padding:0!important;
      .nav-link i {
        margin-right: 0 !important;
        margin-left: 0px !important;
      }
    }
    app-left-menu .left-menu.closed .navbar-nav{
      .nav-link i {
        margin-right: 0 !important;
        margin-left: 10px !important;
      }
    }

  }

}

@media screen and (max-width: 601px) {
  .ngx-pagination.responsive {
    .small-screen {
      display: none !important;
    }
    li:not(.small-screen):not(.pagination-previous):not(.pagination-next){
      display: inline-block !important;
    }
  }
}

@media (max-width: 576px) {
  .page-body.direction-rtl,
  .page-body.direction-ltr{

    .top-title-with-buttons {
      padding-bottom: 20px;
      border-bottom: 1.5px solid rgba(0, 14, 35, 0.05);
      &.has-breadcrumb{
        transform: translateY(0px);
        margin-top: 0px;
      }
      .buttons-container{
        display:none;
      }
    }
    .responsive-button-safe-area
    {
      height:100px;
    }
    .responsive-buttons{
      position: fixed;
      width: 100%;
      bottom: 0;
      right:0;
      left:0;
      z-index: 1000;
      background: white;
      padding-top: 30px;
      margin-left: 0px !important;
      margin-right:0px !important;
      margin-bottom: 0px !important;
      .buttons-container{
        display:flex !important;
        flex-direction: column;
        justify-content: center !important;
        width: 100%;
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 10px!important;
        }
      }
    }
    .add-button,
    .outline-button,
    .red-button,
    .blue-button,
    .outline-red{
      width: 100%!important;
      margin-top:10px!important;
      margin-left:0!important;
      margin-right:0!important;
      &.btn-big{
        min-width: 100%!important;
      }
    }
    .row.full-width-submit-container{
      padding-right: 0!important;
      padding-left: 0!important;
    }
    // li.xng-breadcrumb-separator, li.xng-breadcrumb-item span {
    //     display: none;
    // }

        .foo-tabs-menu{
            .buttons-container{
                display:none;
            }
        }

    .outline-button{
      .btn-icons{
        display:none;
      }
    }


    .add-seach-icon:before {
      content: "";
      top: 12px;
    }
    .container-box-account .account-price{
      margin-top:0!important;
      margin-bottom:0!important;
    }
  }
  app-more ngb-accordion .card .card-body{
    padding: 15px 10px!important;
  }
  .app-custom-dialog-container {
    .mat-mdc-dialog-container {

      .mat-mdc-dialog-actions {
        flex-direction: column;
        padding-top: 5px !important;
        .foo-btn-outline-secondary {
          margin-right: 0 !important;
          margin-bottom: 5px;
        }
      }
    }
  }
}


@media (max-width: 330px) {
  .page-content-wrapper {
    margin-top: 62px !important;
    padding: 20px 15px !important;
  }
  .unauth-body .page-content-wrapper {
    padding: 20px 0px !important;
  }
  .send-money{
    font-size: 12px!important;
    i {
      font-size: 16px!important;
      margin-right: 5px!important;
      width: 17px!important;
      height: 17px!important;
    }
  }
}

/* Start Foo Main Colors */
$primary-color: #02B2DA;
$secondary-color: #011936;
$third-color: #2E3E56;
$success-color: #57CA99;
$info-color: #02B2DA;
$warning-color: #FFB800;
$danger-color: #FF0000;
$light-color: #AFB7C5;
$white-color: #ffffff;
$dark-color: #011936;
/* End Foo Main Colors */

$btn-shadow-color: rgba($primary-color, 0.4);
$btn-outline-shadow-color: rgba($secondary-color, 0.2);

$left-menu-primary-color: #011936;
$left-menu-secondary-color: #ffffff;

$left-menu-active-color: $primary-color;

$sub-left-menu-primary-color: $left-menu-primary-color;
$sub-left-menu-secondary-color: #AFB7C5;
$sub-left-menu-active-color: $left-menu-active-color;
$sub-left-menu-hover-color: rgba($sub-left-menu-active-color, 0.05);

$tab-active-color: $primary-color;

$list-row-hover-color: rgba($primary-color, 0.05);
$item-tag-hover-color: rgba($primary-color, 0.05);

$shimmer-color: linear-gradient(to right, #F5F5F5 4%, #FBFBFB 25%, #f5f5f5 36%);
$shimmer-light-color: #f5f5f5;
$shimmer-none-color: none;

$border-color: #F5F6F8;
$tags-color: $light-color;
$readonly-color: #F2F2F2;
$dialog-overlay-color: rgba(46, 62, 86, 0.45);
$dialog-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);

$loader-width: 100px;
$loader-color: $secondary-color;

$listing-row-height: 32px;

$container-border-color: rgba($light-color,0.2);
$container-shadow-color: rgba($dark-color, 0.05);
$box-border-color: transparent;
$box-shadow-color: rgba($primary-color, 0.11);
$container-min-height: 54px;
$foo-sizes: 1000;

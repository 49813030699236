/*
=======================================================================
                            [Table of Contents]
=========================================================================
00. Global Styles
01. Select boxes
02. Tabs
03. Search Box
04. Forms
05. Responsive
*/

/* 00. Start Global Styles CSS */
@import "foo-fonts";
@import "foo-mat-datepicker-deprecated-style";

html, body {
  margin: 0;
  height: 100%;
}

// Start: From proj.scss
button[ngbdropdowntoggle] {
  display: flex !important;

  &::after {
    display: none !important;
  }
}

div[ngbDropdownMenu] {
  box-shadow: 0px -2px 32px rgb(39 55 79 / 14%);
  border: none !important;
  border-radius: 10px !important;

  &::before {
    position: absolute;
    top: -5px;
    left: unset;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--foo-white-color);
    border-left: 7px solid transparent;
    content: "";
  }

  &[x-placement="bottom-right"] {
    &::before {
      right: 15px;
    }
  }

  &[x-placement="bottom-left"] {
    &::before {
      left: 15px;
    }
  }

  &[x-placement="bottom"] {
    &::before {
      right: 46%;
    }
  }

  button[ngbdropdownitem] {
    color: var(--foo-dark-color);
    font-weight: 400 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 14px !important;
  }
}
.more-options-dropdown.dropdown-menu.show {
  &[x-placement="bottom-right"]{
    left: 10px;
  }
  &[x-placement="bottom-left"]{
    right: 10px !important;
    left: auto;
  }
  position: relative !important;
}

.foo-status-success span:not(.label-row2),
.foo-status-danger span:not(.label-row2),
.foo-status-warning span:not(.label-row2),
.foo-status-light span:not(.label-row2),
.foo-status-info span:not(.label-row2) {
  font-size: 12px;
  padding: 0 4px !important;
  border-radius: 5px;
}

.foo-status-success span:not(.label-row2) {
  color: var(--foo-success-color); //--status-green-color
  background: var(--foo-success-bg); //--status-green-background
}

.foo-status-danger span:not(.label-row2) {
  color: var(--foo-danger-color); //--status-red-color
  background: var(--foo-danger-bg); //--status-red-background
}

.foo-status-warning span:not(.label-row2) {
  color: var(--foo-warning-color); //--status-orange-color
  background: var(--foo-warning-bg); //--status-orange-background
}

.foo-status-light span:not(.label-row2) {
  color: var(--foo-light-color); //--status-grey-color
  background: var(--foo-light-bg); //--status-grey-background
}

.foo-status-info span:not(.label-row2) {
  color: var(--foo-info-color); //--status-blue-color
  background: var(--foo-info-bg); //--status-blue-background
}

.filter-close-button {
  position: absolute;
  top: 22px;
  right: 20px;
  cursor: pointer;
}

.card-listing-image {
  height: 26px;
  width: 55px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.card-listing-image-sm {
  height: 35px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.box-shadow-none {
  box-shadow: none !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer;
}

a.disabled {
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled {
  opacity: 0.7;
}

.color-dark-blue {
  color: var(--foo-dark-color);
}

button.close-icon,
button.filter-close-button {
  transition: .5s all ease;

  &:hover {
    opacity: 0.6;
  }
}

mat-form-field[class*=status-box-] {
  height: 100%;
  max-width: 150px;
  width: fit-content !important;
  display: block;
  padding: 0.5px 2px;
  border-radius: 5px;
  font-size: 12px;

  .mat-mdc-select-arrow-wrapper {
    padding: 0 2px;
  }

  .mat-mdc-select-arrow {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: inline-block;
    padding: 2px;
    margin-top: -4px;
    border-width: 0 1px 1px 0;
    border-style: solid;
  }
  &.mat-focused .mat-mdc-select-arrow {
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
  }

  .mat-mdc-form-field-infix {
    width: fit-content;
  }
  &.mat-mdc-form-field-type-mat-select{
    .mat-mdc-form-field-infix{
      padding-top: unset !important;
      padding-bottom: unset !important;
    }
  }
}
.mat-mdc-select-arrow-wrapper {
  height: 16px !important;

  .mat-mdc-select-arrow {
    width: 0 !important;
    height: 0 !important;
    margin: 0 4px;

    svg {
      display: none;
    }
  }
}
.mdc-text-field{
  padding:0 !important;
  &.mdc-text-field--filled{
    --mdc-filled-text-field-container-shape: 5px !important;
    --mdc-filled-text-field-active-indicator-height: 0px !important;
    --mdc-filled-text-field-focus-active-indicator-height: 0px !important;

  }
}

mat-form-field.status-box-active,
mat-form-field.status-box-green {
  background: var(--foo-success-bg) !important;
  .mat-mdc-select-value-text {
    color: var(--foo-success-color) !important;
  }
  .mat-mdc-select-arrow {
    border-color: var(--foo-success-color); //--status-green-color
  }
}

mat-form-field.status-box-failed,
mat-form-field.status-box-red
{
  background: var(--foo-danger-bg) !important;
  .mat-mdc-select-value-text {
    color: var(--foo-danger-color) !important;
  }
  .mat-mdc-select-arrow {
    border-color: var(--foo-danger-color); //--status-red-color
  }
}

mat-form-field.status-box-gray {
  background: var(--foo-light-bg) !important;
  .mat-mdc-select-value-text {
    color: var(--foo-light-color) !important;
  }
  .mat-mdc-select-arrow {
    border-color: var(--foo-light-color); //--status-grey-color
  }
}

// End: From proj.scss


app-root {
  z-index: 99;
}

/* Start Popup CSS*/
.app-custom-dialog-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .mat-mdc-dialog-container {
    padding-top: unset;
    position: relative;
    border-radius: 10px;
    box-shadow: var(--mdc-dialog-container-elevation);
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
.cdk-overlay-pane {
  .mat-mdc-dialog-container {
    overflow-y: unset !important;
    --mdc-dialog-supporting-text-font: var(--foo-primary-font);
    --mdc-dialog-subhead-font: var(--foo-primary-font);
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
    --mdc-dialog-container-shape: 10px;
    --mdc-dialog-container-elevation: var(--foo-dialog-shadow);
    --mdc-dialog-supporting-text-line-height: 17px;
    --mdc-dialog-supporting-text-size: 1rem;
    --mat-dialog-transition-duration: 0ms !important;

    .mdc-dialog__surface {
      overflow: hidden;
      box-shadow: none;

      .mdc-dialog__title {
        &::before {
          height: unset;
        }
      }

      .mdc-dialog__content {
        padding: 16px 24px !important;
        letter-spacing: unset;
      }

      .mat-mdc-dialog-actions {
        .foo-btn-outline-secondary {
          cursor: pointer;
          margin-right: 20px;
        }

      }
    }
  }
}


.relative-dialog-container {
  .mat-mdc-dialog-container {
    position: relative;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  background: var(--foo-border-color);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--foo-light-color);
  border-radius: 10px;
}

.cdk-global-overlay-wrapper[dir="rtl"] {
  button.close-icon {
    right: unset;
    left: 4%;
  }
}

.cdk-overlay-backdrop.app-customer-backdrop.cdk-overlay-backdrop-showing {
  background: var(--foo-dialog-overlay-color);
  backdrop-filter: blur(12px);
}

/* End Popup CSS*/
.page-body.direction-rtl,
.page-body.direction-ltr {
  &.showskyline:before {
    content: "";
    position: fixed;
    background-image: url('/assets/img/skyline-gray.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    bottom: 0px;
    right: 10px;
    display: block;
    height: 90vh;
    width: 60%;
    max-width: 550px;
    background-size: contain;
    z-index: 0;
  }

  margin: 0;
  font-family: var(--foo-primary-font);

  .noresult_found {
    text-align: center;
    max-width: 200px;
    margin: 0 auto;

    span {
      opacity: 0.5;
    }
  }

  span {
    &[data-order=""]::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: 15px;
      background: url("/assets/img/sort-arrows.svg");
      width: 6px;
      height: 10px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &[data-order="asc"]::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: 15px;
      background: url("/assets/img/sort-asc.svg");
      width: 6px;
      height: 10px;
      background-position: top;
      background-repeat: no-repeat;
    }

    &[data-order="desc"]::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: 15px;
      background: url("/assets/img/sort-desc.svg");
      width: 6px;
      height: 10px;
      background-position: bottom;
      background-repeat: no-repeat;
    }
  }

  .form-control[readonly]:not(.input-calendar),
  input[readonly]:not(.input-calendar) {
    background: var(--foo-readonly-color);
    border: none !important;
    box-shadow: none !important;
    cursor: default;
    resize: none;
    :not(.timepicker-dial__control){
      color: var(--foo-light-color) !important;
    }
    &:not(.mat-datepicker-input){
      border-radius: 10px;
      height: 40px;
    }
  }

  ng-select[readonly],
  .ng-select.ng-select-disabled > .ng-select-container {
    background: var(--foo-readonly-color) !important;
    box-shadow: none !important;
  }

  img {
    max-width: 100%;
  }

  .half-opacity {
    opacity: 0.5;
  }

  .aligncenter {
    text-align: center;
  }



  @each $key, $value in $bootstrap-padding-values {
    .mt-#{$key} {
      margin-top: #{$value} !important;
    }
    .mb-#{$key} {
      margin-bottom: #{$value} !important;
    }
    .pt-#{$key} {
      padding-top: #{$value} !important;
    }
    .pb-#{$key} {
      padding-bottom: #{$value} !important;
    }
  }

  @for $i from 1 to 6 {
    .mt-#{$i}px {
      margin-top: #{$i}px !important;
    }
    .mb-#{$i}px {
      margin-bottom: #{$i}px !important;
    }
    .pt-#{$i}px {
      padding-top: #{$i}px !important;
    }
    .pb-#{$i}px {
      padding-bottom: #{$i}px !important;
    }
  }

  @for $i from 6 to 100 {

    .mt-#{$i} {
      margin-top: #{$i}px !important;
    }
    .mb-#{$i} {
      margin-bottom: #{$i}px !important;
    }
    .pt-#{$i} {
      padding-top: #{$i}px !important;
    }
    .pb-#{$i} {
      padding-bottom: #{$i}px !important;
    }

  }


  .no-mb {
    margin-bottom: 0 !important;
  }

  .no-mt {
    margin-top: 0 !important;
  }

  .mlr-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mar-3 {
    margin: 0.3rem !important;
  }

  .plr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .nopadd {
    padding: 0 !important;
  }

  .nomargin {
    margin: 0 !important;
  }

  .alignright {
    text-align: right;
  }

  .dashed-border-bottom {
    border-bottom: 1px dashed #bbbbbb;
  }

  .no-border-bottom {
    border-bottom: 0 !important;
  }

  .section-title {
    font-family: var(--foo-main-font) !important;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--foo-dark-color);
    margin-bottom: 0;
    text-transform: capitalize;
    font-style: normal;
    letter-spacing: 0.01em;
  }

  .section-subtitle {
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #262626;
  }

  .tox .tox-notification--warn, .tox .tox-notification--warning {
    display: none;
  }

  [class*="container-box-"] {
    word-break: break-all;
    background: var(--foo-white-color);
    box-sizing: border-box;
    box-shadow: var(--foo-container-shadow);
    border-radius: 10px;
    padding: 10px 15px;
    margin: 0 0 10px 0;
    &:not(.container-box-seperatedlist) {
      border: var(--foo-box-border);
    }
  }

  .container-box-roles {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: var(--foo-dark-color);
      margin-bottom: 0px !important;
      text-transform: capitalize;
    }

    p {
      font-size: 14px;
      line-height: 19px;
      font-family: var(--foo-primary-font);
      color: var(--foo-light-color);
      margin-bottom: 0 !important;
    }

    .role-actions {
      flex-shrink: 0
    }
  }

  .container-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--foo-dark-color);
    font-family: var(--foo-primary-font);
  }

  .foo-default-btn {
    background: linear-gradient(90.26deg, #376CD2 45.27%, var(--foo-primary-color) 93.85%);
    box-shadow: 0px 6px 22px rgba(128, 88, 235, 0.2);
    border-radius: 14px;
    color: white;
    padding: 8px 25px;
    min-width: 150px;
    display: inline-block;
    text-align: center;
    height: 50px;
    outline: 0;
    border: 0;

    &:focus,
    &:hover {
      box-shadow: var(--foo-btn-shadow);
    }

    &:active {
      box-shadow: none !important;
    }
  }

  .icon-right {
    cursor: pointer;
  }
  .mat-mdc-standard-chip{
    --mdc-chip-label-text-size: 14px;
  }
  .mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--foo-primary-color) !important;
  }

  .mat-mdc-checkbox {
    font-family: var(--foo-primary-font);
    text-align: start;
    .mdc-label{
      margin-bottom: 0 !important;
      padding-left: 8px;
      font-size: 14px;
    }
    .mdc-checkbox{
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
    .mdc-checkbox__background {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }
    .mdc-checkbox__ripple{
      display: none;
    }
  }
  .mdc-checkbox__checkmark{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 84%;
    right: unset !important;
  }

  .mat-mdc-checkbox a {
    color: var(--foo-primary-color);
  }

  .mat-mdc-checkbox-disabled {
    .mdc-checkbox__background {
      opacity: 0.2;
      border-radius: 4px;
    }

    &:not(.mat-mdc-checkbox-checked) {
      .mdc-checkbox__background {
        border-color: #0000008a !important;
        background-color: var(--foo-light-color) !important;
      }
    }

    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        border-color: var(--mdc-checkbox-selected-hover-icon-color) !important;
        background-color: var(--mdc-checkbox-selected-hover-icon-color) !important;
      }
    }
  }

  .mdc-radio__inner-circle {
    background-color: var(--foo-primary-color);
  }

  .mdc-radio__outer-circle {
    border-color: var(--foo-light-color) !important;
  }

  .mat-mdc-radio-checked {
    .mdc-radio__outer-circle {
      border-color: var(--foo-primary-color) !important;
    }
    .mdc-radio__inner-circle {
      transform: scale(0.6);
    }
  }
  .mat-mdc-radio-button {
    .mdc-radio__background,
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      width: 25px !important;
      height: 25px !important;
    }
    .mdc-label {
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  .mat-mdc-radio-button {
    &.mat-accent {
      --mdc-radio-selected-focus-icon-color: #02B2DA;
      --mdc-radio-selected-hover-icon-color: #02B2DA;
      --mdc-radio-selected-icon-color: #02B2DA;
      --mdc-radio-selected-pressed-icon-color: #02B2DA;
      --mat-radio-checked-ripple-color: #02B2DA;

      .mat-radio-ripple  {
        .mat-ripple-element{
          background-color: var(--foo-primary-bg) !important;
        }
      }
    }
    .mdc-radio {
      width: 24px;
      height: 24px;
      --mdc-radio-state-layer-size: 0;
    }
    .mdc-radio__native-control{
      width: 24px;
      height: 24px;
    }
  }

  .mat-mdc-radio-button.shimmer-animate {
    .mdc-form-field {
      animation: shimmer 3s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%) !important;
      background-size: 1000px 100% !important;
      color: transparent !important;
      height: 50%;
      position: relative;
      top: 25%;
    }

    .mdc-radio,.mdc-label {
      visibility: hidden !important;
    }
  }


  .container-box-seperatedlist {
    .mat-accordion {
      .mat-expansion-panel:last-of-type {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .mat-expansion-panel:first-of-type {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
    }
  }

  mat-accordion {
    .mat-expansion-panel-header {
      border-radius: var(--mat-expansion-container-shape);
      .padding-element {
        display: none;
      }
    }

    .mat-expansion-panel-header[aria-disabled=true] {
      color: initial;

      .padding-element {
        display: block !important;
        width: 55.5px;
      }
    }

    .mat-expansion-indicator::after {
      content: "";
      padding: 3px;
      transform: rotate(0deg);
      vertical-align: middle;
      background: url('/assets/img/arrow_down.svg');
      display: block;
      width: 24px;
      height: 24px;
      border: 0 !important;
      background-size: contain;
    }
  }

  span.seperator {
    color: rgba(185, 191, 201, 0.4);
    margin: 0 30px;
  }

  .mat-mdc-dialog-actions {
    margin-bottom: 0;
    padding-bottom: 24px;
  }


  .foo-btn-outline-secondary {
    &.btn-big {
      min-width: 300px;
    }

    &.btn-black {
      border: 1px solid #1c1612;
      color: #1d1611;
    }

    &.btn-md {
      min-width: 245px;
    }

    &.btn-center {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &:active {
      box-shadow: none !important;
    }

    .btn-icons {
      width: 21px;
      height: 21px;
      margin-right: 5px;
    }
  }

  .foo-btn-outline-danger {
    &.btn-center {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &.btn-big {
      min-width: 300px;
    }

    &.btn-md {
      min-width: 245px;
    }
  }

  .mat-mdc-dialog-content {
    padding-top: 15px;
  }

  .send-invitation-btn {
    background: transparent;
    border: none;
    outline: 0;

    span {
      font-size: 12px;
      font-weight: normal;
      color: var(--foo-success-color);
    }
  }
  .mat-mdc-dialog-title {
    margin: 0;
    display: flex;
    border-bottom: 1px solid var(--foo-container-border-color);
    min-height: 50px;
    padding: 26px 24px 20px;
    text-transform: capitalize;

    p {
      margin: 0;
      padding: 0;
      font-family: var(--foo-primary-font);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      width: 100%;
    }
  }

  .mdc-dialog__container .close-icon {
    margin-top: 1.2rem !important;
  }
  .close-icon {
    background: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    right: 4%;
    margin-top: 0.25rem !important;
    z-index: 1;
  }

  .min-righticon-container {
    position: relative;
    display: block;

    span.min-righticon-form {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: 15px;
      font-weight: bold;
      color: var(--foo-dark-color);
      font-size: 14px;
    }
  }

  .pendingTransactionRed {
    /* color: red !important;*/
  }

  .leftNumberInput {
    position: relative;

    span {
      font-weight: bold;
      position: absolute;
      bottom: 15%;
      left: 15px;
    }

    input {
      padding-left: 55px !important;
    }
  }

  /* Switch 1 Specific Style Start */
  input[type="checkbox"].switch_1 {
    font-size: 27px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1.8em;
    height: 1em;
    background: #ddd;
    border-radius: 3em !important;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  input[type="checkbox"].switch_1:checked {
    background: var(--foo-primary-color);
  }

  input[type="checkbox"].switch_1::after {
    position: absolute;
    content: "";
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
    box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    transform: scale(.85);
    left: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  input[type="checkbox"].switch_1:checked::after {
    left: calc(100% - 1em);
  }

  input[type="checkbox"].switch_1:focus {
    border: none !important;
    outline: none !important;
  }

  button.send-money {
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid var(--foo-primary-color) !important;
    }

    &:active {
      border: 1px solid transparent !important;
    }
  }

  button.circle-actions {
    outline: 0;
    background: transparent;
    border-radius: 50%;
    margin: 0 5px !important;
    padding: 0;
    border: 1px solid transparent;
    display: inline-flex;
    vertical-align: middle;
    &.foo-icons-arrow-down-btn {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .check-button {
    background: var(--foo-white-color);
    border: 1px solid transparent;
    box-shadow: 0px 12px 22px var(--foo-primary-bg);
    border-radius: 14px !important;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: capitalize;
    color: var(--foo-primary-color);
    width: max-content;
    min-width: 150px;
    padding: 15px 25px;
    display: inline-block;
    outline: 0 !important;
    margin-right: 20px;

    &.btn-big {
      min-width: 300px;
    }

    &.btn-md {
      min-width: 245px;
    }

    &.btn-center {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--foo-primary-color);
      text-decoration: none;
    }

    &.selected {
      border: 1px solid var(--foo-primary-color);
    }
  }

  button[disabled=""] {
    opacity: 0.40;
    cursor: default;
  }

  button:focus {
    outline: 0;
    box-shadow: none;
  }

  .bold-hover {
    display: inline-block;

    &:hover {
      // font-weight: 900;
      // text-shadow: 0 0.000001px rgba(7,183,205,0.5), 0 -0.000001px rgba(7,183,205,0.5), 0.000001px 0 rgba(7,183,205,0.5), -0.000001px 0 rgba(7,183,205,0.5);
      text-decoration: none;
      transform-origin: 50% 50%;
      transform: scale(1.05) !important;
      transition: .3s;
      transform-box: fill-box;
    }

    &:active {
      // font-weight: 800;
      // text-shadow: 0 0.000001px rgba(7,183,205,0.5), 0 -0.000001px rgba(7,183,205,0.5), 0.000001px 0 rgba(7,183,205,0.5), -0.000001px 0 rgba(7,183,205,0.5);
      text-decoration: none;
      transform-origin: 50% 50%;
      transform: scale(1.05) !important;
      transition: .3s;
      transform-box: fill-box;
    }
  }

  .langSwitch {
    min-width: 62px !important;
    display: inline-block;
    cursor: pointer;
    margin: 0 25px;
  }

  i[class*="foo-icons-"] {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }


  .foo-icons-password {
    width: 25px !important;
    height: 25px !important;
    position: absolute;
    top: calc(50% - 12.5px);
    right: 15px;
    cursor: pointer;
  }

  button.btn-purple {
    background: rgba(205, 190, 247, 0.3);
    border-radius: 13px !important;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--foo-primary-color);
    height: 40px;
    margin-right: 10px !important;
    border: 0;
    padding: 0 15px;
  }

  .middle-action-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ngb-accordion .card {
    margin-bottom: 20px;
    border: 0 !important;

    .card-header {
      position: relative;
      background: var(--foo-white-color);
      border: 0.5px solid rgba(185, 191, 201, 0.2);
      box-sizing: border-box;
      box-shadow: 0px 6px 37px rgba(7, 64, 152, 0.05);
      border-radius: 13px !important;
      display: flex;
      height: 83px;
      margin-bottom: 0 !important;

      h5 {
        font-size: 16px;
        font-weight: 800;
        line-height: 140.5%;
        color: var(--foo-dark-color);
      }
    }

    .collapse {
      &.show {
        background: #F9F9F9;
        border-radius: 0px 0px 14px 14px;
      }
    }

    .card-body {
      font-size: 14px;
      line-height: 140.5%;
      color: var(--foo-dark-color);
      font-family: var(--foo-primary-font);
    }
  }

  app-more ngb-accordion .card {
    margin-bottom: 0 !important;

    .card-header {
      padding: 0 !important;
      box-shadow: none !important;
      border: 0 !important;
      height: fit-content !important;
    }

    .card-body {
      padding: 15px 20px;
      background: var(--foo-white-color);
    }
  }

  app-atms {
    ngb-accordion .card {
      box-shadow: 0px 4px 15px rgba(26, 20, 65, 0.05);
      border-radius: 13px !important;
      background: white;
      margin-bottom: 20px;
      border: 0 !important;

      .card-header {
        position: relative;
        box-sizing: border-box;
        display: flex;
        height: 83px;
        margin-bottom: 0 !important;
        box-shadow: none !important;
        border: 0 !important;
        border-radius: 0 !important;

        h5 {
          font-size: 16px;
          line-height: 140.5%;
          color: var(--foo-dark-color);
        }

        .isopen {
          h5 {
            color: var(--foo-primary-color);
          }
        }
      }

      .collapse {
        &.show {
          background: var(--foo-white-color) !important;
          border-radius: 0px 0px 14px 14px;

          .card-body {
            font-size: 14px;
            line-height: 140.5%;
            color: var(--foo-dark-color);
          }
        }
      }
    }

    ngb-accordion .card-header {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .swiper-wrapper {
      padding: 7px 0 15px 0;
    }

    .gm-style-iw, .gm-style-iw-d {
      padding: 0 !important;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
    .mat-mdc-form-field-suffix, .mat-mdc-form-field-icon-suffix {
      position: absolute;
      right: 10px !important;
      left: unset !important;
      top: 50%;
      transform: translateY(-50%);
    }

    &.mat-mdc-form-field-type-mat-input {
      .mat-mdc-form-field-flex {
        background: var(--foo-white-color) !important;
        box-shadow: none;
        height: 40px;
        border-radius: 10px !important;

        .mat-datepicker-input {
          background: transparent !important;
        }
      }
    }

    span.mat-mdc-form-field-label-wrapper {
      height: 40px;
      padding: 0 15px 0 30px;
      top: 0;
      .mat-mdc-form-field-label {
        padding: 0 15px;
        font-family: var(--foo-primary-font);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: var(--foo-light-color);
        top: 50%;
        transform: translateY(-50%);
      }

      input::placeholder {
        color: var(--foo-light-color) !important;
      }
    }

    .mat-mdc-form-field-infix {
      margin: 0;
      padding: 0 3px;
      border: 0;
      font-family: var(--foo-primary-font);
      font-size: 14px;
      line-height: 1.25;
      min-height: auto;
    }

    .mat-mdc-form-field-subscript-wrapper,.mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  mat-datepicker-content,
  ngx-mat-datetime-content {
    thead.mat-calendar-table-header {
      th {
        text-align: center !important;
      }
    }
  }
  .timepicker{
    .mat-mdc-form-field-icon-suffix>.mat-icon {
      padding: 3px;
    }
  }
  .ngx-mat-timepicker-dialog{
    .mat-mdc-dialog-content{
      padding: 0 !important;
    }
  }
  ngx-mat-datetime-content {
    .ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-icon-button .mat-icon {
      margin: 0 !important;
    }
    .mat-mdc-form-field-infix {
      padding: 4px 0 0 0 !important;
    }
  }
  // used for datetimepicker
  ngx-mat-timepicker {
    .mat-mdc-form-field-flex {
      height: unset !important;
    }
    .mat-mdc-form-field-infix {
      padding: 6px 0 !important;
    }

    form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-icon-button .mat-icon {
      margin-left: 1px !important;
    }
  }

  mat-form-field.datetimepicker,
  mat-form-field.datepicker {
    &:not(.timepicker){
      .mat-mdc-form-field-flex {
        input {
          cursor: default;
        }
      }
    }
    width: 100%;

    .mat-icon {
      margin: 0 !important;
    }
    .mat-mdc-form-field-flex {
      padding: 1em 0.75em 0 0.75em !important;
      input {
        background: transparent !important;
      }
    }
    .mat-mdc-text-field-wrapper{
      background: none;
      will-change: auto;
      .mat-mdc-form-field-focus-overlay{
        display: none;
      }
    }

    .mat-mdc-form-field-infix {
      margin: 0;
      padding: 0 3px;
      border: 0;
      font-family: var(--foo-primary-font);
      font-size: 14px;
      line-height: 1.25;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    display: none;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label.mat-form-field-empty {
    display: block;
  }

  .mat-mdc-text-field-wrapper{
    background: none;
    .mat-mdc-form-field-focus-overlay{
      display: none;
    }
  }

  .verified-color {
    color: var(--foo-success-color);
  }

  .error-color {
    color: var(--foo-danger-color);
  }

  .row.full-width-submit-container {
    padding: 20px;
    background: var(--foo-white-color);
    box-shadow: 0px -6px 13px rgba(0, 0, 0, 0.06);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0px;
    margin: 0;
    width: auto;

    .buttons-aligned {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;

      button.foo-btn-outline-secondary,
      button.foo-btn-primary {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .full-width-submit-safe-area {
    height: 80px;
  }

  div[class*="status-box-"],
  span[class*="status-box-"] {
    border-radius: 9px;
    height: 30px;
    padding: 0 15px;
    width: max-content;
    min-width: 100px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    font-family: var(--foo-primary-font);
    margin: 0 20px;

    &.big-status {
      width: 100%;
      margin: 0;
    }
  }

  .status-box-received {
    background: rgba(192, 192, 192, 0.1);
    color: var(--foo-light-color);
  }

  .status-box-pending {
    background: var(--foo-warning-bg);
    color: var(--foo-warning-color);
    .mat-mdc-select-value-text {
      color: var(--foo-warning-color);
    }
    .mat-mdc-select-arrow {
      border-color: var(--foo-warning-color);
    }
  }

  .status-box-failed {
    background: rgba(255, 0, 0, 0.17);
    color: var(--foo-danger-color);
    .mat-mdc-select-value-text {
      color: var(--foo-danger-color);
    }
    .mat-mdc-select-arrow {
      border-color: var(--foo-danger-color);
    }
  }

  .status-box-active {
    background: var(--approve-background);
    color: var(--foo-success-color);
    .mat-mdc-select-value-text {
      color: var(--foo-success-color);
    }
    .mat-mdc-select-arrow {
      border-color: var(--foo-success-color);
    }
  }
  li.xng-breadcrumb-item:only-of-type {
    display: none;
  }

  ol.xng-breadcrumb-list {
    margin-bottom: 0px;

    li {
      font-size: 12px;
      line-height: 16px;
      color: var(--foo-light-color);
      z-index: 999;
      margin-bottom: 1rem;

      a {
        text-decoration: none;
        color: var(--foo-light-color);
        outline: 0 !important;
      }

      label {
        margin-bottom: 0;
      }

      .foo-icon {
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          box-shadow: var(--foo-btn-shadow);
        }

        &:active {
          box-shadow: none;
        }
      }

      .xng-breadcrumb-trail {
        font-weight: 700;
        color: var(--foo-light-color);
      }
    }
  }

  .auto-slider .swiper-slide {
    flex-shrink: 1;
    max-width: fit-content;
  }

  ngb-alert.alert {
    position: fixed;
    top: 12px;
    width: 400px;
    right: 12px;
    z-index: 9999999999;

    .btn-close{
      padding: 0.9rem 1.25rem;
      font-size: 0.8em;
      padding-top: 0px;
      padding-bottom: 0px;
      height: 100%;
    }
  }

  .top-title-with-buttons {
    display: flex;
    align-items: center;
  }

  .hover-scale:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.05);
  }

  .has-error {
    box-shadow: 0px 2px 12px rgba(7, 183, 205, 0.16) !important;
    border: 1px solid #FF4E49 !important;
  }

  .error-field-message {
    font-size: 10px;
    line-height: 14px;
    color: #FF4E49;
    margin-top: 10px;
    font-family: var(--foo-primary-font);
  }

  .desc-box {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 14px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--foo-dark-color);
    margin: 20px auto 0;
    padding: 20px;

    a {
      color: var(--foo-primary-color);
      text-decoration: none !important;
      white-space: nowrap;
    }
  }

  .cdk-overlay-container {
    position: fixed;
    z-index: 1100;
  }


  .filter-panel {
    position: absolute;
    width: 50%;
    z-index: 1;
    background: var(--foo-white-color);
    box-shadow: var(--foo-dialog-shadow);
    border-radius: 10px;
    max-width: 630px;
    right: 50%;
    transform: translate(50%, -30px);

    .filter-panel--title {
      color: var(--foo-dark-color);
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .mat-mdc-checkbox {
      margin: 0 5px 5px;
    }
  }

  ////* Listing Styles */////
  .listing-component-1row framework-listing .data-container .container-box-list .transaction-info span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .listing-component-1row framework-listing .data-container .container-box-list div,
  .listing-component-1row dynamic-listing .data-container .mat-expansion-panel-header-title div,
  .listing-component-1row dynamic-listing .data-container .mat-expansion-panel-header-title .transaction-info span,
  .listing-component-1row dynamic-listing .data-container .basicListing div,
  .listing-component-1row dynamic-listing .data-container .basicListing .transaction-info span  {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  framework-listing .unset-1row span, dynamic-listing .unset-1row span {
    text-overflow: unset !important;
    overflow: unset !important;
    white-space: unset !important;
  }

  dynamic-listing {
    .mat-content.mat-content-hide-toggle  {
      margin-right: 0;
    }
    .mat-expansion-panel-header-title, .basicListing {
      min-width: 0;
      .info-cols {
        .values-container {
          min-width: 0;
        }
        .resp-label {
          min-width: 100px !important;
        }
      }
      div {
        overflow: hidden;
      }
    }
  }

  dynamic-listing, framework-listing {
    .list-header  {
      display: flex;
    }
    .info-cols > div {
      width: 100%;
    }
  }

  .overflow-ellipsis, .overflow-ellipsis * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  /* 00. END Global Styles CSS */

  /* 01. Start Select boxes CSS */
  .ng-select.ng-select-single:not(.ng-select-disabled) .ng-select-container,
  .ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
  .ng-select > .ng-select-container {
    background: var(--foo-white-color);
    box-shadow: none;
    border-radius: 10px;
    height: 40px;
    padding: 0 15px;
  }
  .ng-select.ng-select-single.ng-select-disabled .ng-select-container{
    background: var(--foo-white-color);
    border-radius: 10px;
    height: 40px;
    padding: 0 15px;
    background: var(--foo-readonly-color);
    border: none !important;
    box-shadow: none !important;
    cursor: default;
    span.ng-value-label {
      color: var(--foo-light-color) !important;
    }
  }

  .ng-select.ng-select-multiple {
    border-radius: 10px !important;

    .ng-select-container {
      background: var(--foo-white-color);
      box-shadow: none;
      border-radius: 10px;
      height: auto !important;
      padding: 0 15px;

      .ng-value-container {
        .ng-placeholder {
          padding: 0 !important;
        }

        input {
          padding: 0 !important;
        }

        .ng-input {
          padding-left: 0 !important;
        }
      }

      input[type="text"] {
        border: 0 !important;
      }
    }
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    border: 0;
    background: url('/assets/img/arrow_down.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    width: 24px;
    height: 24px;
    margin: auto;
    display: block;
  }

  .ng-select.ng-select-opened .ng-arrow {
    transform: rotate(180deg);
  }

  .ng-select .ng-clear-wrapper {
    .ng-clear {
      color: var(--foo-light-color);
    }
  }

  .ng-select .ng-placeholder {
    color: var(--foo-light-color);
    font-family: var(--foo-primary-font);
    font-size: 14px;
  }

  .shimmer-animate {
    .non-svg-color-filter,
    .success-svg-color-filter,
    .failed-svg-color-filter,
    .basic-svg-color-filter {
      filter: none !important;
    }

    ng-select {
      animation: shimmer 3s infinite linear;
      background: var(--foo-shimmer-color);
      background-size: 1000px 100%;
      border-radius: 4px;
      color: transparent !important;
      min-height: 40px;
      min-width: 100px;

      //* {
      //  display: none !important;
      //}
    }

    button {
      &[disabled=""] {
        &:hover {
          box-shadow: unset !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    border-radius: 0px 0px 10px 10px !important;
    background: var(--foo-white-color);
    box-shadow: none !important;
    border: 0px;
    margin-top: 2px;

    .scroll-host {
      line-height: 40px !important;
      border-radius: 0px 0px 10px 10px;
    }

    .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
      color: var(--foo-dark-color);
      background: var(--foo-item-tag-hover-color);
      font-weight: bold;

      b {
        font-weight: 400 !important;
      }
    }

    .ng-dropdown-panel-items .ng-option.ng-option-marked {
      color: var(--foo-dark-color);
      background: var(--foo-item-tag-hover-color);
    }

    .ng-dropdown-panel-items .ng-option {
      padding: 15px 20px !important;
    }
  }

  .container-box-select-big .ng-select.ng-select-single .ng-select-container,
  .container-box-select-big .ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
    height: 75px !important;
    border-radius: 10px;
  }

  .container-box-select-big {
    padding: 0 !important;
  }

  .container-box-select-big .ng-select > .ng-select-container,
  .container-box-select-big .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
  .container-box-select-big .ng-select .ng-select-container:hover {
    border-color: #ced4da;
    border: 0;
    padding: 0 20px;
    box-shadow: none;
    font-weight: 800;
  }

  .container-box-select-big .ng-select h2 {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: var(--foo-dark-color);
    white-space: break-spaces;
  }

  .container-box-select-big .ng-select h3 {
    font-size: 14px;
    line-height: 22px;
    color: var(--foo-light-color); // --gray4
    margin: 0;
    white-space: break-spaces;
    direction: ltr !important;
  }

  .ng-select p,
  .ng-select h2,
  .ng-select h3 {
    margin: 0;
  }

  .ng-select .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-left: 0 !important;
    padding-right: 0 !important;
    top: unset;
  }

  .ng-select:not(.ng-select-searchable) .ng-select-container .ng-value-container .ng-input > input {
    padding-left: 12px;
    padding-right: 12px;
    caret-color: transparent;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

  }

  .ng-select {
    border-radius: 10px;
    border: 1px solid #ced4da;

    &.search-bar-design {
      .ng-select-container {
        border: none !important;
        background-color: var(--foo-border-color) !important;
      }

      &.ng-select-focused > .ng-select-container {
        background-color: unset !important;
      }

      input {
        cursor: text !important;

        &:not(:focus) {
          border: none !important;
        }
      }
    }

    &.autocomplete {
      .ng-arrow-wrapper {
        display: none !important;
      }
    }

    &.ng-select-focused {
      .ng-select-container {
        border: 1px solid var(--foo-light-color) !important;
        background: none !important;
      }
    }

    .ng-select-container {
      .ng-value-container {
        .ng-value {
          > .ng-star-inserted {
            padding-left: 0 !important;
          }
        }
      }
    }
  }

  .ng-select.ng-select-multiple {

    .ng-select-container {
      .ng-value-container {
        .ng-value {
          > .ng-star-inserted {
            padding-left: 5px !important;
          }
        }
      }
    }
  }

  /* 01. END Select boxes CSS */
  /* 02. Start Tabs CSS */
  .foo-tabs-menu {
    position: relative;

    .nav-pills li a {
      margin: 14px 2px 18px;
    }
  }

  .buttons-container {
    text-align: right;
    display: inline-flex;
    justify-content: flex-end;
  }

  .responsive-buttons {
    .buttons-container {
      display: none;
    }
  }

  .foo-tabs-menu::after {
    background: rgba(185, 191, 201, 0.2);
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    right: 15px;
    left: 15px;
  }

  .foo-tabs-menu.no-border-bottom::after {
    display: none;
  }

  .nav-pills {
    li {
      position: relative;
      display: flex;
      margin-right: 25px;

      a {
        display: block;
        padding: 0 15px;
        border: 0;
        margin: 10px 2px 20px;
        font-weight: normal;
        font-family: var(--foo-primary-font);
        font-size: 16px;
        line-height: 25px;
        color: var(--foo-light-color); // --gray4
        text-decoration: none;
        text-transform: capitalize;
        text-align: center;

        &:hover {
          color: var(--foo-tab-active-color)
        }

        &.active {
          color: var(--foo-tab-active-color) !important;
          font-weight: 700;
          font-family: var(--foo-primary-font);

          &::after {
            width: calc(100% - 30px) !important;
            height: 4px !important;
            border-radius: 5px 5px 0 0 !important;
            margin: 0px 15px !important;
            background: var(--foo-tab-active-color) !important;
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            right: 0;
          }
        }
      }
    }
  }

  .pills-with-icons {
    li {
      a {
        display: inline-flex !important;
        min-width: 200px;
        justify-content: space-evenly;
        align-items: center;
        background: var(--foo-white-color);
        box-sizing: border-box;
        border-radius: 13px;
        margin: 0 10px !important;
        border: 1px solid transparent !important;
        box-shadow: 0px 4px 15px rgba(26, 20, 65, 0.05);
        padding: 10px !important;

        img {
          width: 40px;
          height: 40px;
        }

        &.active {
          border: 1px solid var(--foo-primary-color) !important;
          box-shadow: none;

          &::after {
            display: none !important;
          }
        }
      }

      &:first-child {
        a {
          margin-left: 0 !important;
        }
      }

      &:last-child {
        a {
          margin-right: 0 !important;
        }
      }
    }
  }

  .row > .tab-content {
    padding: 0 15px;
    width: 100%;
  }

  swiper {
    width: 100%;

    .swiper-slide {
      min-width: fit-content;
    }
  }

  // .pills-with-icons{
  //     swiper {
  //         .swiper-slide {
  //             min-width: -webkit-fit-content;
  //             min-width: -moz-fit-content;
  //             min-width: fit-content;
  //             max-width: max-content;
  //         }
  //     }
  // }
  /* 02. END Tabs CSS */

  /* 03. Start Search Box CSS */
  .search-container {
    background: transparent;
    margin-right: 0;
    margin-left: 0;
    border-radius: 10px;

    &:not(.search-bar-container) {
      padding: 24px;
    }
  }

  .add-seach-icon {
    display: flex;

    input[type="text"] {
      background: var(--foo-readonly-color) !important;
      border: none !important;
    }
  }

  .add-seach-icon:before {
    content: "";
    position: relative;
    /*top: calc( 50% - 12px);
    left: 25px;*/
    top: 10px;
    left: 34px;
    background: url('/assets/img/search-btn.svg');
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
  }

  .custom-clear-input,
  .ng-select .ng-clear-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 20px;
    height: 20px;

    .ng-clear {
      position: absolute;
      mask-image: url('/assets/img/close.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      -webkit-mask-image: url('/assets/img/close.svg');
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      background-color: var(--foo-light-color);
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      line-height: unset;
      cursor: pointer;
      margin: auto;
      right: 4px;
    }
  }

  .search-container,
  .ng-select {
    input[type="text"] {
      width: 100%;
      background: transparent;
      box-sizing: border-box !important;
      border-radius: 10px;
      padding: 0 15px 0 15px !important;
      height: 31px;
      box-shadow: none;
      -webkit-appearance: none;
      font-family: var(--foo-primary-font);

      &.search-icon {
        height: 50px;
        margin-right: 20px;
        box-shadow: none !important;
        padding: 0 15px 0 40px !important
      }
    }
  }

  .search-container {
    &.search-bar-container {
      &:not(.has-search-icon-btn) {
        input {
          height: 100% !important;
          min-height: 40px;
        }
      }
    }

    input {
      &::placeholder {
        color: var(--foo-light-color) !important;
        font-family: var(--foo-primary-font);
      }
    }
  }

  .clear-search,
  .search-btn {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--foo-primary-color);
    display: inline-block;
    font-family: var(--foo-primary-font);
    cursor: pointer;
  }

  .clear-search {
    margin-right: 35px;
  }

  .clear-search-container {
    text-align: right;
  }

  .more-options {
    align-items: center;
    display: flex;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--foo-dark-color);

    img {
      transform: rotate(180deg);
      margin-right: 10px;
    }

    &.isOpen {
      img {
        transform: rotate(0deg);
      }
    }
  }

  div.account-action-box {
    .circle-actions-container {
      gap: 10px;

      button.circle-actions.circle-actions-small {
        margin: 0px !important;
      }
    }

    .rightBtnDesign {
      display: flex !important;
      justify-content: flex-end;
    }
  }

  .search-container .rightNumberInput {
    position: relative;

    span {
      font-weight: bold;
      position: absolute;
      bottom: 15%;
      right: 25px;
    }

    input {
      padding-right: 50px !important;
    }
  }

  /* 03. END Search Box CSS */

  /* 04. Start Forms CSS */
  .form-group {
    position: relative;
  }

  .form-control,
  .form-input,
  .iti > input {
    -webkit-appearance: none;
    width: 100%;
    background: var(--foo-white-color);
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid var(--foo-light-color);
    outline: 0;
    color: var(--foo-dark-color);
    caret-color: var(--foo-dark-color);
    font-family: var(--foo-primary-font);
    font-size: 14px;

    &:not(textarea) {
      height: 40px;
    }

    &:not([type="tel"]):not(.ng-select) {
      padding: 15px;
    }

    &:not(.ng-select):focus, &:not(.ng-select):active {
      &:not([readonly]) {
        box-shadow: none;
        border: 1px solid var(--foo-primary-color) !important;
      }
    }

    &.has-error {
      box-shadow: none !important;
      border: 1px solid #FF4E49 !important;

      &:focus, &:active {
        box-shadow: none !important;
        border: 1px solid #FF4E49 !important;
      }
    }

    &[type="text"],
    &[type="text"]:focus,
    &[type="text"]:visited,
    &[type="password"],
    &[type="password"]:focus,
    &[type="password"]:visited,
    &[type="email"],
    &[type="email"]:focus,
    &[type="email"]:visited,
    &[type="textarea"],
    &[type="textarea"]:focus,
    &[type="tetextareaxt"]:visited,
    &[type="number"],
    &[type="number"]:focus,
    &[type="number"]:visited,
    &[type="file"],
    &[type="file"]:focus,
    &[type="file"]:visited,
    &[type="date"],
    &[type="date"]:focus,
    &[type="date"]:visited,
    &[type="phone"],
    &[type="phone"]:focus,
    &[type="phone"]:visited {
      &:not([readonly]) {
        font-family: var(--foo-primary-font);
        width: 100%;
        caret-color: var(--foo-dark-color);
        background: var(--foo-white-color);
        box-shadow: none;
        border-radius: 10px;
        border: 1px solid var(--foo-light-color);
        padding: 15px;
        height: 40px;
        outline: 0;
        color: var(--foo-dark-color);

        &:focus, &:active {
          box-shadow: none !important;
          border: 1px solid var(--foo-primary-color) !important;
        }

        &.has-error {
          box-shadow: none !important;
          border: 1px solid #FF4E49 !important;

          &:focus, &:active {
            box-shadow: none !important;
            border: 1px solid #FF4E49 !important;
          }
        }
      }
    }
  }

  .form-control.ng-select {
    height: auto;
    padding: 0px;
    line-height: 30px;
    border: none;

    .ng-dropdown-panel {
      border: none !important;
      box-shadow: 0px 5px 12px rgba(#0F0F0F, 0.06) !important;
      padding: 0px;
      border-radius: 10px !important;

      .ng-option {
        padding: 2px 12px !important;
        border-radius: 10px !important;
      }
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: var(--foo-primary-color);
    }
  }

  .ng-select:not(.form-control) {
    position: inherit;
  }

  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
  }

  .form-control {
    font-size: 14px !important;
  }

  .form-control::placeholder,
  .form-input::placeholder {
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--foo-light-color);
  }

  .form-label, .mdc-label{
    font-family: var(--foo-primary-font);
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: var(--foo-dark-color);
    margin-bottom: 5px;
    width: 100%;

    span.delete {
      font-family: var(--foo-primary-font);
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      text-transform: capitalize;
      color: var(--foo-primary-color);
      font-weight: 800;
      float: right;
    }
  }

  textarea.form-control {
    width: 100%;
    background: var(--foo-white-color);
    box-shadow: none;
    border-radius: 10px !important;
    border: 1px solid var(--foo-light-color);
    padding: 15px;
    min-height: 120px !important;
    outline: 0;
    -webkit-appearance: none;

    &:focus, &:active {
      box-shadow: none !important;
      border: 1px solid var(--foo-primary-color) !important;
    }

    &.has-error {
      box-shadow: none !important;
      border: 1px solid #FF4E49 !important;

      &:focus, &:active {
        box-shadow: none !important;
        border: 1px solid #FF4E49 !important;
      }
    }
  }

  .dateInput {
    position: relative;
  }

  input.form-control.input-calendar {
    width: 100% !important;
    display: inline-block !important;
    border-radius: 10px !important;
    background: var(--foo-white-color);
    box-shadow: none;
    height: 40px;
    -webkit-appearance: none;
  }

  .input-calendar-append {
    width: 40px;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 46px;
    transform: translateY(-50%);
  }

  button.btn.btn-outline-secondary.calendar {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    box-sizing: border-box;
    border-radius: 0 13px 13px 0;
    height: 30px;
    width: 30px;
    outline: 0 !important;

    &::after {
      filter: unset;
      width: 24px;
      height: 24px;
    }
  }

  button.btn.btn-outline-secondary.calendar::after {
    content: "";
    -webkit-mask: url('/assets/img/calendar.svg') no-repeat 100% 100%;
    mask: url('/assets/img/calendar.svg') no-repeat 100% 100%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background: var(--foo-primary-color) !important;
    filter: unset !important;
    display: block;
    width: 20px;
    height: 20px;
  }

  .hasrightinfo {
    position: relative;

    input {
      padding-right: 40px !important;
    }
  }

  span.input-right-info {
    position: absolute;
    top: 17px;
    right: 25px;
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    text-align: right;
    color: var(--foo-dark-color);
  }

  /* 04. END Forms CSS */

  /* 05. Start Shimmering CSS */

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }

  .section-title.shimmer-animate {
    opacity: 0 !important;
  }

  .foo-btn-primary.shimmer-animate {
    display: none !important;
  }


  /* 05. END Shimmering CSS */

  /* 06. Start Paginator CSS */
  pagination-controls {
    margin: auto;
  }

  .ngx-pagination {
    display: flex;

  }

  .ngx-pagination li:not(.pagination-next):not(.pagination-previous) {
    width: fit-content;
    height: 34px;
    text-align: center;
    border-radius: 6px;
    line-height: 20px;
    font-weight: normal;
    font-size: 14px;
    opacity: 0.7;

    a {
      width: fit-content;
      height: 34px;
      padding: 0 0.625rem;
      border-radius: 8px;
      color: var(--foo-light-color);
      line-height: 34px !important;
    }

    a:hover {
      color: var(--foo-primary-color) !important;
      background: transparent;
      text-decoration: none;
    }
  }

  .ngx-pagination .pagination-previous a::before, .ngx-pagination .pagination-previous.disabled::before,
  .ngx-pagination .pagination-next a::after, .ngx-pagination .pagination-next.disabled::after {
    content: "";
  }

  .pagination-previous,
  .pagination-next {
    a {
      color: var(--foo-primary-color) !important;
    }
  }

  .pagination-previous.disabled,
  .pagination-next.disabled {
    color: var(--foo-light-color); // --gray4
  }

  .ngx-pagination .current {
    padding: 0 0.625rem;
    background: var(--foo-item-tag-hover-color);
    color: var(--foo-primary-color);
    line-height: 34px !important;
    opacity: 100% !important;
    font-weight: 700 !important;
  }

  .ngx-pagination.responsive {
    padding-left: 0px !important;
  }

  li {
    &.pagination-previous,
    &.pagination-next {
      -webkit-mask: url('/assets/img/arrow_right_g.svg') no-repeat 100% 100%;
      mask: url('/assets/img/arrow_right_g.svg') no-repeat 100% 100%;
      -webkit-mask-size: auto;
      mask-size: auto;
      background: var(--foo-light-color) !important;
      filter: unset !important;
      display: block;
      width: 24px;
      height: 24px;

      &:not(.disabled) {
        background: var(--foo-primary-color) !important;
        filter: unset !important;
      }

      &.disabled {
        opacity: 0.7;
      }

      a {
        opacity: 0;
      }
    }

    &.pagination-previous {
      transform: rotateY(180deg);
    }
  }

  /* 06. END Paginator CSS */

  /*portal styles*/
  .page-content-wrapper.menu-invisible {
    .portal-page-content {
      width: calc(100% - 20%);
      display: inline-block !important;
      margin-left: 20% !important;
      margin-right: 0px !important;
      transition: 0.5s;
      position: relative;
      margin-top: 5px;
    }
  }


  .txt-container {
    display: inline-flex;
    text-align: center;
    margin-bottom: 10px;

    width: 100%;
    justify-content: center;
  }

  .txt-big {
    font-family: "Frutiger", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    line-height: 115%;
    text-align: center;
    margin-bottom: 0px;
    font-size: 40px;
    padding: 1vh 0px;
  }

  .txt-md {
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    padding-right: 7px;
    vertical-align: middle;
    font-size: 16px;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
  }

  .txt-orange {
    color: var(--foo-primary-color);
  }

  .txt-gray {
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    text-align: center;
    /* Grey/Dark */
    color: var(--foo-light-color);
  }

  .detail-card {
    position: absolute;
    background: #e9ecee;
    border-radius: 10px;
  }

  .card-content-padding {
    padding: 4vh;
  }


  .btns-container {
    width: 462px !important;
  }

  .btn-46 {
    min-width: 46%;
  }

  .numbr-txt {
    //styleName: Heading/Bold/16;
    font-family: var(--foo-primary-font);
    /*  font-size: 16px;*/
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: 6px !important;
    font-size: 16px;
    color: var(--foo-dark-color);
  }

  img.login-logo {
    margin-bottom: 25px;
    margin-top: 15px;
    max-width: 135px;
    max-height: 40px;
  }

  .centered {
    text-align: center;
  }

  .ng-select .ng-arrow-wrapper {
    padding: 0 !important;
    text-align: right;
  }
}


.page-body.direction-ltr {
  @each $key, $value in $bootstrap-padding-values {
    .mr-#{$key} {
      margin-right: #{$value} !important;
    }
    .ml-#{$key} {
      margin-left: #{$value} !important;
    }
    .pr-#{$key} {
      padding-right: #{$value} !important;
    }
    .pl-#{$key} {
      padding-left: #{$value} !important;
    }
  }

  @for $i from 1 to 6 {
    .mr-#{$i}px {
      margin-right: #{$i}px !important;
    }
    .ml-#{$i}px {
      margin-left: #{$i}px !important;
    }
    .pr-#{$i}px {
      padding-right: #{$i}px !important;
    }
    .pl-#{$i}px {
      padding-left: #{$i}px !important;
    }
  }

  @for $i from 6 to 100 {

    .mr-#{$i} {
      margin-right: #{$i}px !important;
    }
    .ml-#{$i} {
      margin-left: #{$i}px !important;
    }
    .pr-#{$i} {
      padding-right: #{$i}px !important;
    }
    .pl-#{$i} {
      padding-left: #{$i}px !important;
    }
  }

}

.page-body.direction-ltr .custom-select {
  font-size: 15px !important;
}

@import "bpmn"; //camunda modeler with panel

.iti {
  display: block !important;
}

.iti .search-container {
  padding: 5px 10px 15px 10px !important;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
  overflow-x: hidden;
  white-space: break-spaces;
}

.iti__country {
  margin-bottom: 1%;
}

.iti__flag-container {
  direction: ltr !important;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

.iti input#country-search-box {
  border-radius: 0 !important;
}


/* datepicker */

.datepicker,
.datetimepicker {
  width: 100% !important;
  background: var(--foo-white-color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--foo-light-color) !important;
  color: var(--foo-dark-color) !important;
}

.mat-icon {
  margin-bottom: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.cdk-overlay-pane {
  div.mat-mdc-select-panel {
    width: unset;
    overflow: visible;
    box-shadow: 0 5px 30px #00000014!important;
    border-radius: 10px!important;
    padding: 0;
    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      font-weight: 700;
    }
    .mat-mdc-option{
      border-radius: inherit;
      min-height: 40px;
      padding-left: 14px;
      padding-right: 14px;
      .mat-pseudo-checkbox-minimal {
        margin-left: 8px;
      }
    }
  }
}
.mat-mdc-form-field{
  &:hover,
  &.mat-focused{
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
  }
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: var(--foo-primary-color);
}
.mat-custom-select-pannel {
  background: var(--foo-white-color);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.08) !important;
  border-radius: 10px !important;
  position: relative;
  left: 0;
  overflow: visible !important;

  .mat-mdc-option {

    &:not(.mat-option-disabled) {
      &.mat-mdc-selected,
      &:hover,
      &:focus {
        background: var(--foo-item-tag-hover-color);
        color: var(--foo-dark-color);
        font-weight: bold;
        border-radius: 10px !important;
      }
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    z-index: 1;
    top: -7px;
    left: 30px;
  }

  &::before {
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #eee;
    border-left: 7px solid transparent;
  }

  &::after {
    top: -6px;
    left: 31px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--foo-white-color);
    border-left: 6px solid transparent;
  }
}

.cdk-overlay-container {
  z-index: 100000 !important;
}

.page-body {
  .filter-panel.dialog-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    transform: unset;
    z-index: 99998 !important;
    max-width: unset;
    background: transparent;
    &::before{
      content: '';
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: var(--foo-dialog-overlay-color);
      backdrop-filter: blur(12px);
      display: none;
    }
    .tab-content {
      position: relative;
      max-width: 646px;
      margin: auto;
      width: 646px;
      border-radius: 13px;
      box-shadow: var(--foo-dialog-shadow);
      background: #fff;
      color: rgba(0, 0, 0, .87);
      -webkit-transition: all .2s ease-in-out!important;
      transition: all .2s ease-in-out!important;
      transform: scale(0.7);
      opacity: 0;
    }

    &.opened {
      opacity: 1;
      visibility: visible;
      .tab-content {
        transform: none;
        opacity: 1;
      }
      &::before{
        display: block;
      }
    }
    &.closed{
      opacity: 0;
      visibility: hidden;
      .tab-content{
        -webkit-transition: unset;
        transition: unset;
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}


.shimmer-animate {
  .shimmer-item:not(.values-container),
  .shimmer-item.values-container span,
  .shimmer-even,
  .shimmer-odd {
    cursor: default !important;
    max-width: 100%;
    opacity: 1 !important;
    animation: shimmer 3s infinite linear;
    background: var(--foo-shimmer-color);
    background-size: 1000px 100%;
    border-radius: 4px;
    color: transparent !important;
    min-height: 20px;
    word-break: keep-all !important;
    white-space: nowrap !important;
    pointer-events: none !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    * {
      color: transparent !important;
      background: none !important;
      border: none;
    }

    &:hover {
      background-color: initial !important;
    }

    &::after {
      display: none;
    }

    span.reportMobileValue {
      display: none !important;
    }

    img {
      opacity: 0 !important;
    }

  }

  .shimmer-even {
    width: #{randomNum(100, 160)}px;
  }

  .shimmer-odd {
    width: #{randomNum(80, 100)}px;
  }

  .shimmer-w-auto {
    width: auto !important;
  }

  .shimmer-i-block {
    display: inline-block;
  }

  .shimmer-row,
  .shimmer-row-equal-mobile {
    cursor: default !important;

    &:hover {
      background-color: initial !important;
    }

    &:nth-child(even) {
      .shimmer-item:not(.shimmer-w-auto) {
        width: #{randomNum(100, 160)}px;
      }
    }

    &:nth-child(odd) {
      .shimmer-item:not(.shimmer-w-auto) {
        width: #{randomNum(80, 100)}px;
      }
    }
  }
}


@media (max-width: 767.98px) {
  .mat-expansion-panel-header {
    height: auto !important;
    min-height: 48px;
  }
  .shimmer-animate {
    .shimmer-row-equal-mobile {
      .shimmer-item:not(.shimmer-w-auto) {
        width: #{randomNum(80, 100)}px !important;
      }
    }
  }
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  color: var(--foo-light-color) !important;
}

:-moz-placeholder {
  font-size: 14px !important;
  color: var(--foo-light-color) !important;
}

::-moz-placeholder {
  font-size: 14px !important;
  color: var(--foo-light-color) !important;
}

:-ms-input-placeholder {
  font-size: 14px !important;
  color: var(--foo-light-color) !important;
}

// ::input-placeholder {
//   font-size: 14px !important;
//   color: #AFB7C5 !important;
// }

::placeholder {
  font-size: 14px !important;
  color: var(--foo-light-color) !important;
}

.ng-select .ng-select-container {
  border: 1px solid var(--foo-light-color);
}

// to display scrollbar for popup
.display-scrollbar {
  ::-webkit-scrollbar {
    display: initial;
  }
}

.add-new-line::after {
  content: '\A';
  white-space: pre;
}

.downloadBtn {
  border: 1px solid rgba(var(--foo-light-color), 0.3) !important;
  border-radius: 10px !important;
  padding: 8px 15px !important;
  font-size: 14px !important;
  color: var(--foo-primary-color) !important;
  text-decoration: none !important;
  line-height: 1.8 !important;
  width: 100% !important;
  text-align: left !important;

  &:focus,
  &:hover {
    text-decoration: none !important;
    box-shadow: none !important;
  }

  .foo-icon {
    float: right;
  }
}

.box-listing-row-value {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.box-listing-new-row-value {
  font-size: 14px !important;
  font-weight: 400 !important;
}

// from proj.scss
@media (max-width: 767.98px) {
  .page-body.direction-ltr {
    search-bar .search-container {
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }
  .page-body.direction-rtl {
    search-bar .search-container {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }
  .foo-btn-primary, .foo-btn-outline-secondary {
    &.responsive-button {
      width: 100% !important;
    }
  }

  .page-body.direction-rtl div[class*=container-box-], .page-body.direction-ltr div[class*=container-box-] {
    margin-bottom: 20px !important;
  }
}

//end


// form build styles

.form-builder-dialog {
  overflow: hidden !important;
}

.form-builder {
  input::-webkit-calendar-picker-indicator {
    position: initial;
  }

  select {
    appearance: auto !important;
  }

  .stage-wrap {
    border: 1px solid var(--foo-light-color) !important;
    border-radius: 5px;
  }

  select[multiple] {
    height: auto !important;
  }

  .form-control {
    font-family: var(--foo-primary-font);
    width: 100% !important;
    caret-color: var(--foo-dark-color) !important;
    background: var(--foo-white-color) !important;
    box-shadow: none;
    border-radius: 8px !important;
    border: 1px solid var(--foo-light-color) !important;
    padding: 15px !important;
    height: 34px !important;
    outline: 0;
    color: var(--foo-dark-color);
  }

  label {
    font-family: var(--foo-primary-font);
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: var(--foo-dark-color);
    margin-bottom: 5px;
  }
}

// end

.page-body.direction-ltr {
  .custom-clear-input {
    position: absolute;
    right: 35px !important;
    left: unset !important;
  }
}

.page-body.direction-rtl {
  .custom-clear-input {
    position: absolute;
    left: 35px !important;
    right: unset !important;
  }
}

// new styles


.flex {
  display: flex;
}

.clickable-text {
  color: var(--foo-primary-color) !important;
  font-family: var(--foo-primary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cdk-drag-preview {
  min-height: 40px;
  display: flex;
  align-items: center;
  font-family: var(--foo-primary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--foo-dark-color);
  background: var(--foo-white-color);
  box-shadow: 0px 2px 15px rgba(39, 55, 79, 0.06);
  border-radius: 10px;

  button {
    display: none;
  }

  .actions-cols {
    display: none;
  }

  .mat-expansion-indicator {
    display: none;
  }
}

.property-info {
  .property-label {
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.003em;
    color: var(--foo-light-color);
  }

  .property-value {
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--foo-dark-color);
  }
}


.view-btn {
  height: 16px;
  min-width: 51px;
  border-radius: 4px !important;
  font-family: var(--foo-primary-font);
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center;
  letter-spacing: 0.003em;
  color: var(--foo-dark-color);
  background: var(--foo-white-color);
  border: 1px solid var(--foo-dark-color);
  text-transform: capitalize;
  width: max-content;
  display: inline-flex;
  outline: 0 !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  margin: 0 !important;
}

// prevent mouse events on loading
.is-loading {
  * {
    pointer-events: none;
    user-select: none;
  }
}

// info dropdown
.info-dropdown {
  top: 5px !important;

  .dropdown-menu {
    padding: 14px !important;
    font-family: var(--foo-main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 14px;
    letter-spacing: .003em;
    color: var(--foo-dark-color) !important;
    background: var(--foo-white-color);

    &::before {
      right: 3.5% !important;
    }
  }
}

.dropdown-menu-inner-icon::before {
  right: 36% !important;
}


// end

.mat-mdc-icon-button {
  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    padding: 8px 0;
  }

  .mat-button-focus-overlay {
    display: none !important;
  }
}
.page-body.direction-ltr .dropdown-item:hover, .page-body.direction-ltr .dropdown-item:focus,
.page-body.direction-rtl .dropdown-item:hover, .page-body.direction-rtl .dropdown-item:focus {
  background-color: var(--foo-item-tag-hover-color) !important;
}


// List Sorting

.sorting-button {
  min-width: 80px !important;
  line-height: 16px;
  padding: 0 10px !important;
}

.sorting-dropdown {
  top: 7px !important;

  div.dropdown-menu {
    min-width: 170px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow: hidden;

    &::before {
      display: none !important;
    }

    .dropdown-item {
      height: 35px;
      padding-left: 14px !important;
      padding-right: 14px !important;
    }
  }
}

.mat-mdc-dialog-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.app-custom-dialog-container {
  max-height: 95vh;
}

.app-initial-overflow {
  .mat-mdc-dialog-container {
    overflow: initial !important;
  }

  .mat-mdc-dialog-content {
    overflow: initial !important;
  }
}

.app-dialog-no-capitalize {
  .mat-mdc-dialog-title {
    text-transform: none !important;
  }
}


// CSS of Autocomplete ng-select: with no arrow down and looks like the search
@media (min-width: 768px) {
  .customSearchContainer {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    display: flex;
    padding: 0 24px 24px 0 !important;

    .ng-select {
      padding-right: 24px !important;
    }
  }
}

.customSearchContainer {
  form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 768px) and (max-width: 991px) {
  .customSearchContainer {
    width: 100%;
  }
}

@media (min-width: 1800px) {
  .customSearchContainer {
    width: 32vw !important;
  }
}

@media (min-width: 1200px) {
  .customSearchContainer {
    width: 40vw !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .customSearchContainer {
    width: 50vw !important;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5),
(min-width: 768px) and (max-width: 991px) {
  .customSearchContainer {
    width: 70%;
  }
}

@media (max-width: 575px),
(min-width: 576px) and (max-width: 767px),
(min-width: 320px) and (max-width: 475px) {
  .page-body .search-bar-container {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .customSearchContainer {
    margin-right: 0 !important;
    padding-right: 0 !important;

    .ng-select {
      margin: 0 0 15px !important;
    }
  }
}

.messageCount {
  font-size: 14px;
  color: var(--foo-light-color);
  position: absolute;
  right: 0;
}

.no-capitalize {
  text-transform: none !important;
}

.container-box-list div:has(span.overflow-visible) {
  overflow: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

// Used in add-user component when multiple values are selected
.ng-select.ng-select-multiple {
  .ng-select-container {
    &.ng-has-value {
      height: fit-content !important;
    }
  }
}

.tox-tinymce-aux{z-index:99999999999 !important;}

.swiper-slide > li {
  cursor: pointer;
}
.text-transform-none{
  text-transform: none !important;
}

ng-select:not(.ng-select-disabled) {
  .ng-select-container {
    cursor: pointer !important;

    .ng-value-container > div > input {
      cursor: pointer !important;
    }
  }
}
.page-body.direction-ltr .dropdown-item.dropdown-item-notclickable:hover,
.page-body.direction-ltr .dropdown-item.dropdown-item-notclickable:focus,
.page-body.direction-rtl .dropdown-item.dropdown-item-notclickable:hover,
.page-body.direction-rtl .dropdown-item.dropdown-item-notclickable:focus {
  color: initial !important;
  background-color: initial !important;
}


.mat-mdc-tooltip {
  font-size: 12px !important;
  font-family: var(--foo-main-font) !important;
}

.input-tags-container {
  &.mat-form-field-appearance-fill {
    .mat-mdc-form-field-flex {
      min-height: 40px;
      border-radius: 10px !important;
      border: 1px solid var(--foo-light-color);
      padding: 10px !important;
      background: var(--foo-white-color);
    }
  }
}
.logout-menu{
  .dropdown-menu{
    min-width: 11rem !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.mat-mdc-input-element {
  box-sizing: border-box !important;
}

.page-body.direction-ltr .no-gutters>.col, .page-body.direction-ltr .no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

hr{
  opacity : 1;
}

// Approval Transactions Style
.approval-transactions-dialog {
  --foo-listing-row-height: 100%;
  .values-container {
    width: 100%;;
  }
}


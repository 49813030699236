@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/gotham/Gotham Black Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Light-07.otf'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Avenir-Light-07.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Avenir-Light-07.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Avenir-Light-07.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/Avenir-Light-07.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Avenir-Light-07.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Medium-09.otf'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Avenir-Medium-09.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Avenir-Medium-09.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Avenir-Medium-09.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/Avenir-Medium-09.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Avenir-Medium-09.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Heavy-05.otf'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Avenir-Heavy-05.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Avenir-Heavy-05.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Avenir-Heavy-05.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/Avenir-Heavy-05.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Avenir-Heavy-05.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Black-03.otf'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Avenir-Black-03.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Avenir-Black-03.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Avenir-Black-03.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/Avenir-Black-03.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Avenir-Black-03.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Roman';
  src: url('/assets/fonts/Avenir-Roman-12.otf'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Avenir-Roman-12.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/Avenir-Roman-12.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/Avenir-Roman-12.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/Avenir-Roman-12.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/Avenir-Roman-12.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

:root {
  --foo-primary-font: Helvetica, sans-serif;
}

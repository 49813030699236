
$navbar-light-color: var(--foo-left-menu-secondary-color) !default;
$navbar-light-hover-color: var(--foo-left-menu-active-color) !default;

$primary:       $primary-color !default;
$secondary:     $secondary-color !default;
$success:       $success-color !default;
$info:          $info-color !default;
$warning:       $warning-color !default;
$danger:        $danger-color !default;
$light:         $light-color !default;
$dark:          $dark-color !default;

$bootstrap-padding-values: () !default;

$bootstrap-padding-values: map-merge(
    (
      "0": 0rem,
      "1": 0.25rem,
      "2": 0.5rem,
      "3": 1rem,
      "4": 1.5rem,
      "5": 3rem,
      "auto": "auto"
    ),
    $bootstrap-padding-values
);

@import "bootstrap/scss/bootstrap.scss";

:root {
  --bs-danger-rgb: 220, 53, 69;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-disabled-bg: transparent;
  --bs-secondary-color:var(--foo-light-color);
  --mdc-checkbox-unselected-hover-icon-color: var(--mdc-checkbox-unselected-icon-color);
  --mdc-checkbox-unselected-focus-icon-color: var(--mdc-checkbox-unselected-icon-color);
}
.mat-expansion-panel{
  --mat-expansion-container-shape: 10px !important;
}
.page-body.direction-rtl {
    direction: rtl;
}

mat-calendar-header .mat-calendar-hidden-label{
    display: none !important;
  }

  .mat-mdc-form-field-suffix, .mat-mdc-form-field-icon-suffix {
    .mat-mdc-icon-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: inherit;
      width: 2.5em;
      height: 2.5em;
    }
  }
  div[data-popper-placement="bottom-start"] {

    div[ngbdropdownmenu] {
      &::before {
        left: 15px;
      }
    }
  }

  div[data-popper-placement="bottom-end"] {

    div[ngbdropdownmenu] {
      &::before {
        right: 15px;
      }
    }
  }
  div[data-popper-placement="bottom"] {
    div[ngbdropdownmenu] {
      &::before {
        right: 46%;
      }
    }
  }
  // bootstrap 5
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    max-width: 100%;
  }


  .form-group {
    margin-bottom: 1rem;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  textarea.form-control {
    min-height: 120px;
  }
  .alert {
    --bs-alert-padding-x: 1.25rem !important;
    --bs-alert-padding-y: .75rem !important;
  }
  .row > search-bar {
    width: auto !important;
    padding: 0 !important;

  }
  label{
    margin-bottom: 0.5rem;
  }
  a {
    text-decoration: none !important;
  }

  .left-menu-details {
    height: auto !important;
  }
  .page-body.direction-ltr ol, .page-body.direction-ltr ul {
    padding-left: unset !important;
  }

  .row > search-bar {
    padding-left: unset;
    padding-right: unset;
  }
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }

  button.btn {
    display: inline-block;
  }

  .modal{
    --bs-modal-border-radius: 10px;
  }
  .page-body.direction-ltr .navbar,.page-body.direction-rtl .navbar{
    --bs-navbar-padding-x: 1rem !important;

  }
  .page-body.direction-ltr, .page-body.direction-rtl{
    .text-end{
      text-align: end !important;
    }
    .text-start{
      text-align: start !important;
    }

    .row {
      --bs-gutter-x: 30px;
      >* {
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
      }
    }

    .col {
      flex: 1 1 100%;
    }

    pagination-controls {
      margin: auto;
      width: auto !important;
    }
    .mat-mdc-checkbox .mdc-checkbox__background {
      border-color: var(--mdc-checkbox-unselected-icon-color);
    }
  }

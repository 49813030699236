@media (max-width: 1024px) {
    .page-body.direction-rtl{
        .page-content-wrapper{
            width:100%;
            margin-right:0!important;
            padding: 20px;
        }
        .left-menu.closed .navbar-nav .nav-link i {
            margin-right: 0 !important;
            margin-left: 20px !important;
        }

        .row.full-width-submit-container {
            right: 0 !important;
        }
    }
}

@media screen and (max-width: 768px){
    .page-body.direction-rtl{
        .page-content-wrapper{
            width:100%;
            margin-right:0!important;
            padding: 20px 24px;
        }
    }

}

@media (max-width: 576px) {
    .page-body.direction-rtl {
        .outline-button{
            text-align: center;
        }
        button.send-money{
            font-size: 14px;
        }
    }
}

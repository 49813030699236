.page-body.direction-rtl {
  .left-menu-search-dropdown {
    i {
      margin-left: 12px;
      margin-right: 0;

      &.dot-item {
        margin-left: 8px !important;
        margin-right: 23px !important;
      }
    }
  }

  .mat-mdc-dialog-title p {
    text-align: right;
  }

  .close-icon{
    left: 26%;
  }
  .mat-mdc-dialog-content{
    text-align: right;
  }
  .row.full-width-submit-container {
    right: 250px;
    left: 0px !important;
  }

  .langSwitch {
    // font-family: 'Avenir';
    //line-height: 30px;
    span {
      padding-bottom: 0px;
    }
  }

  &.showskyline:before {
    content: "";
    position: fixed;
    background-image: url(/assets/img/skyline-gray.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    bottom: 0px;
    left: 10px;
    right: unset;
    display: block;
    height: 90vh;
    width: 60%;
    max-width: 550px;
    background-size: contain;
    z-index: 0;
  }

  ngb-alert.alert {
    position: absolute;
    top: 12px;
    width: 400px;
    right: unset;
    left: 12px;
    z-index: 9999999999;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x) !important;
  }
  ngb-alert.alert {
    .btn-close{
      left: 0;
      right: unset !important;
    }
  }

  .news-img {
    border-radius: 0 13px 13px 0 !important;
  }

  .navbar-nav
  {
    .nav-link {
      img.menu-arrow, span.menu-arrow{
        left: 0!important;
        right: unset!important;
      }
      i {
        margin-right: 0 !important;
        margin-left: 20px !important;
      }
    }
    .nav-item{
      .sub-menu{
        .nav-link {
          i {
            margin-right: 20px !important;
            margin-left: 10px !important;
          }
        }
      }
    }
  }


  .nav {
    padding-right: 0px;
  }


  .left-menu.closed .navbar-nav .nav-item {
    margin: 0!important;
    .nav-link i {
      margin-right: 0 !important;
      margin-left: 0px !important;
    }
    .sub-menu{
      .nav-link {
        i {
          margin-right: 20px !important;
          margin-left: 10px !important;
          display: block;
          position: relative;
          &::before{
            margin-inline-end: 1ch;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 5px;
            display: inline-block;
            background-color: var(--foo-left-menu-secondary-color);
            vertical-align: middle;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .left-menu.closed a.nav-link {
      &:hover {
        span:not(.foo-icon) {
          left: unset !important;
          right: 42px;
          border-radius: 10px 10px 0 !important;
        }
      }
    }
  }

  .login-info p:first-child span {
    direction: ltr !important;
    display: inline-block;
    font-family: var(--foo-main-font) !important;
  }

  .dash-balance a {
    float: left !important;
  }

  .total-balance, .add-money a {
    left: unset !important;
    right: 20px !important;
  }

  app-login {
    h2.main-title {
      line-height: 50px;
    }
  }


  app-left-menu nav {
    left: unset !important;
    right: 0px !important;
  }

  app-left-menu .navbar-nav {
    padding-right: 0 !important;
  }

  .page-content-wrapper {
    margin-right: 250px !important;
    margin-left: 0px !important;
  }

  .page-content-wrapper.menuclosed {
    margin-right: 65px !important;
  }

  app-unauth-home .page-content-wrapper {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  app-header nav.top-nav {
    left: 0;
    right: 250px;
  }

  app-header nav.top-nav.menuclosed {
    left: 0 !important;
    right: 65px !important;
  }

  .clear-search {
    margin-right: 0;
    margin-left: 35px;
  }
  .filter-close-button{
    left: 20px;
    right: unset !important;
  }
  app-notification {
    .container-box-notification .icon-right {
      left: 30px;
      right: unset;
    }
  }

  app-add-account {
    .container-box-new-account .account-container img.pop-info {
      right: unset !important;
      left: 0;
    }

    .container-box-new-account .account-container,
    .container-box-new-account .account-container .account-info {
      margin-left: 20px;
      margin-right: 0px !important;
    }
  }

  app-info-dialog {
    direction: rtl;

    button.close-icon {
      left: 5%;
      right: unset !important;
    }
  }

  summary-info-with-seperator {
    .info-seperator .add-seperator {
      border-left: 1px solid #D8D8D8;
      border-right: 0px !important;
    }
  }

  .logout-menu a.nav-link {
    font-size: 14px;
  }

  ul.nav.nav-pills.pills-with-icons {
    padding: 0;
  }

  .nav-pills {
    li {
      margin-right: unset !important;
      margin-left: 25px;
    }
  }

  .add-seach-icon:before {
    content: "";
    position: relative;
    /*top: calc( 50% - 12px);*/
    left: unset;
    /* right: 20px;*/
    top: 10px;
    right: 34px;
    background: url(/assets/img/search-btn.svg);
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
  }

  .add-seach-icon {
    padding: 0 15px 0 15px !important;
  }

  .search-container input[type="text"] {
    padding: 0 15px 0 15px !important;

    &.search-icon {
      margin-right: 0px !important;
      margin-left: 20px;
      padding-right: 35px !important;
      padding-left: 15px !important;
    }
  }

  .viewmorelink {
    float: left;
  }

  .account-icon {
    margin-left: 25px !important;
    margin-right: 0 !important;
  }

  app-dashboard {
    .add-money {
      background-image: url(/assets/img/dash-money-ar.jpg) !important;
      background-position: 50%;
      background-size: cover;
    }
  }

  button.send-money i {
    margin-right: 0 !important;
    margin-left: 10px !important;
  }

  .mat-mdc-checkbox {
    .mdc-label{
      padding-left: 0 !important;
      padding-right: 8px;
    }
  }

  .money-separator {
    transform: rotate(180deg);
  }

  .container-box-balance div.account-action-box button.send-money i {
    transform: rotate(180deg);
  }

  .logout-menu.logout-open ul.navbar-nav {
    right: unset !important;
    left: 0 !important;
    width: 160px !important;
    padding: 0;

    .nav-link {
      text-align: right;

      img {
        margin-left: 14px;
        margin-right: 0;
        transform: rotate(180deg);
      }
    }
  }

  .logout-menu .foo-logout-third {
    transform: rotate(180deg);
  }
  .clear-search-container {
    text-align: left;
  }

  app-atms .pills-with-icons li {
    margin-right: 10px;
  }

  .form-control::placeholder,
  .form-input::placeholder {
    font-family: var(--foo-main-font) !important;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-suffix, .mat-mdc-form-field-icon-suffix {
      right: unset !important;
      left: 10px !important;
    }
    input {
      text-align: right !important;
    }

    i.foo-icons-password {
      right: unset !important;
      left: 15px;
    }
  }

  ol.xng-breadcrumb-list li img {
    transform: scale(1) rotate(180deg);
    margin-right: 0px;
    margin-left: 10px;

    &.hover-scale:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.05) rotate(180deg);
    }
  }

  ol.xng-breadcrumb-list li .foo-icon {
    margin-right: unset !important;
    margin-left: 10px;
  }

  i.foo-icons-right {
    transform: rotate(180deg);
  }

  .outline-button {
    .btn-icons {
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  app-account {
    .account-icon {
      margin-left: 10px !important;
      margin-right: 0 !important;
    }

    .container-box-account .account-price {
      margin-left: 0;
      margin-right: 25px;
    }

    .icon-right {
      position: absolute;
      right: unset;
      left: 0px;
    }

    button.send-money i.foo-icons-arrow-btn {
      transform: rotate(180deg);
    }
  }

  app-atms {
    .agm-info-window-hours .left-info img,
    .atms-accordions .atm-head .left-info img {
      margin-right: 0px !important;
      margin-left: 20px;
    }
  }

  app-login .max-250 {
    margin-left: 0;
    margin-right: 0;
    text-align: right;
  }

  .container-box-account .account-price {
    margin-left: 0;
    margin-right: 45px;
  }

  span.input-right-info {
    position: absolute;
    top: 17px;
    left: 25px !important;
    right: unset !important;
  }

  ngb-datepicker {
    direction: rtl;

    &.dropdown-menu {
      right: initial !important;
    }

    .ngb-dp-arrow.right .ngb-dp-navigation-chevron {
      transform: rotate(-135deg);
    }

    .ngb-dp-navigation-chevron {
      transform: rotate(45deg);
    }
  }


  app-send-money div.account-info-box .icon-right {
    right: unset !important;
    left: 0;
  }

  app-send-money-beneficiary {
    div.account-action-box .icon-right {
      right: unset !important;
      left: 0 !important;
    }
  }

  app-send-money-details {
    .currencyInput {
      input {
        text-align: left !important;
        padding-right: 0% !important;
        padding-left: 47% !important;
      }

      span {
        right: unset !important;
        left: 40%;
      }
    }
  }

  .input-calendar-append {
    left: 15px;
    right: unset;
  }

  .ng-select .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 0px !important;
    top: 0 !important;
  }
  .ng-select.ng-select-multiple {

    .ng-select-container {
      .ng-value-container {
        .ng-value {
          >.ng-star-inserted {
            padding-right: 5px !important;
          }
        }
      }
    }
  }
  .custom-clear-input,
  .ng-select .ng-clear-wrapper {
    .ng-clear {
      right: unset;
      left: 4px;
    }
  }

  .more-options img {
    margin-right: 0;
    margin-left: 10px;
  }

  .forgot-password {
    text-align: left;
  }

  .verification-box {
    input {
      margin-right: 0 !important;
      margin-left: 20px !important;
    }
  }

  li {
    &.pagination-previous {
      transform: rotateY(0deg);
    }
    &.pagination-next {
      transform: rotateY(180deg);
    }
  }

  .fixedNumberAr {
    direction: ltr;
  }

  .mr-10 {
    margin-left: 10px;
  }

  .pr-10 {
    padding-left: 10px !important;
  }

  .hasrightinfo {
    position: relative;

    input {
      padding-left: 40px !important;
      padding-right: 15px !important;
    }
  }

  .dropdown-menu-inner-icon::before {
    left: 36% !important;
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button {
       width: auto !important;
       padding-left: 4px;
    }
  }

  /*portal styles*/
  .page-content-wrapper.menu-invisible {
    .portal-page-content {
      width: calc(100% - 20%);
      display: inline-block !important;
      margin-right: 20% !important;
      margin-left: 0px !important;
      transition: 0.5s;
      position: relative;
    }
  }


  .txt-container {
    display: inline-flex;
    text-align: center;
    margin-bottom: 10px;

    width: 100%;
    justify-content: center;
  }

  .txt-big {
    // todo use a variable
    font-family: "Frutiger", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    line-height: 115%;
    text-align: center;
    margin-bottom: 0px;
    font-size: 40px;
    padding: 1vh 0px;
  }

  /*@media (max-width: 576px){
    .txt-md {
      font-size: 3.5vw !important;
      padding-top: 1.5vw !important;
    }
  }*/

  .txt-md {
    // todo use a variable
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    padding-right: 7px;
    vertical-align: middle;
    font-size: 16px;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;

  }

  .txt-orange {
    color: var(--foo-primary-color);
  }

  .txt-gray {
    // todo use a variable
    font-family: var(--foo-primary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    text-align: center;
    /* Grey/Dark */
    color: var(--foo-light-color);
  }

  .detail-card {
    position: absolute;
    background: #e9ecee;
    border-radius: 10px;
  }

  .card-content-padding {
    padding: 4vh;
  }


  .btns-container {
    width: 462px !important;
  }

  .btn-46 {
    min-width: 46%;
  }

  @each $key, $value in $bootstrap-padding-values {
    .mr-#{$key} {
      margin-left: #{$value} !important;
    }
    .ml-#{$key} {
      margin-right: #{$value} !important;
    }
    .pr-#{$key} {
      padding-left: #{$value} !important;
    }
    .pl-#{$key} {
      padding-right: #{$value} !important;
    }
  }

  @for $i from 1 to 6 {
    .mr-#{$i}px {
      margin-left: #{$i}px !important;
    }
    .ml-#{$i}px {
      margin-right: #{$i}px !important;
    }
    .pr-#{$i}px {
      padding-left: #{$i}px !important;
    }
    .pl-#{$i}px {
      padding-right: #{$i}px !important;
    }
  }

  @for $i from 6 to 100 {

    .mr-#{$i} {
      margin-left: #{$i}px !important;
    }
    .ml-#{$i} {
      margin-right: #{$i}px !important;
    }
    .pr-#{$i} {
      padding-left: #{$i}px !important;
    }
    .pl-#{$i} {
      padding-right: #{$i}px !important;
    }
  }

  .card-value-div {
    .card-icon {
      margin-left: 10px;
      margin-right: unset !important;
    }
  }
  .filter-close-button {
    right: unset !important;
    left: 20px;

  }
  .alignright{
    text-align: left !important;
  }
  @media (min-width: 577px) {
    .security-first-div {
      border-right:unset !important;
      border-left: 1px solid var(--foo-container-border-color);
    }
  }
  .search-bar-container:not(.dropdown-style){
    padding: 0 0 0 24px !important
  }
  .foo-icon.foo-back-primary,.foo-icon.foo-arrow-right-light{
    transform: scaleX(-1);
  }
  .messageCount{
    left: 0;
    right:unset !important
  }

  .downloadBtn {
    text-align: right !important;

    .foo-icon {
      float: left;
    }
  }

  .float-start {
    float: right !important;
  }

  .float-end {
    float: left !important;
  }

  @media (min-width: 768px) {
    .customSearchContainer {
      padding: 0 0 24px 24px !important;
    }
  }

  .min-righticon-container {
    span.min-righticon-form {
      right: unset;
      left: 15px;
    }
  }

  .gmnoprint.gm-style-mtc-bbw {
    right: 0px !important;
    left: unset !important;
  }

  .gm-control-active.gm-fullscreen-control,
  .gmnoprint.gm-bundled-control {
    left: 0px !important;
    right: unset !important;
  }
}


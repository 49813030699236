@import 'foo_modules/foo-framework/src/lib/theme/foo-text-buttons';
/*
 * @PACKAGE foo-text-buttons
 *
 */
/*
.foo-new-btn{
  @extend .foo-btn-primary;
  width: 150%;
}
*/

@import 'foo_modules/foo-framework/src/lib/theme/foo-icons';
/*
 * @PACKAGE foo-icons
 *
 * Main Classes:
 * ----------------
 * .foo-icon
 * .foo-{icon_name}-{key}
 */
/*
  .foo-icon {
          &.foo-{icon_name}-primary {
            @include foo-icon($color, '/assets/img/menu_white.svg');
            width: 26px;
            height: 26px;
        }
    }
 */

/*
 * @PACKAGE foo-variables
 *
 * TABLE OF CONTENT:
 * ----------------
 * 1. Rgba Function to create colors with opacity to use it as background color
 * 2. Loop theme colors check $foo-theme-colors in (_foo-colors-map.scss)
 * 3. Add Variables names in the loop to set dynamic variables of theme colors
 * 4. How to use: var(--foo-{key}-color)
 *
 *
 *
 * Variables Example:
 * ---------------------
 * --foo-primary-color
 * --foo-secondary-color
 * .
 * .
 * .
 */
 @function rgba-colors($colors) {
  $rgba-colors: ();
  @each $key, $hex in $colors {
    $rgba-color: rgba($hex, 0.12);
    @if $key == "danger" or $key == "info" {
      $rgba-color: rgba($hex, 0.06);
    }
    @else {
      $rgba-color: rgba($hex, 0.12);
    }

    $rgba-colors: map-merge($rgba-colors, ($key: $rgba-color));
  }
  @return $rgba-colors;
}
$rgba-foo-main-theme-colors: rgba-colors($foo-main-theme-colors);
@function rgba-colors-opacity($colors, $opacity) {
  $rgba-colors: ();
  @each $key, $hex in $colors {
    $rgba-color: rgba($hex, 0.2);
    $rgba-colors: map-merge($rgba-colors, ($key: $rgba-color));
  }
  @return $rgba-colors;
}
$rgba-colors-opacity-colors: rgba-colors-opacity($foo-main-theme-colors, 0.2);
:root {
  /* * ------------------------------------------------------------------ * *
   * * 1. Loop theme colors check $foo-theme-colors in (_foo-colors-map.scss) * *
   * * ------------------------------------------------------------------ * */
  @each $key, $color in $foo-theme-colors {
    /* * -------------------------------------------------------------------------- * *
     * * 2. Add Variables names in the loop to set dynamic variables of theme colors * *
     * * -------------------------------------------------------------------------- * */
    --foo-#{$key}-color: #{$color};
  }

  @each $key, $color in $rgba-foo-main-theme-colors {
    /* * -------------------------------------------------------------------------- * *
     * * 3. Add Variables names in the loop to set dynamic rgba variables of theme colors * *
     * * -------------------------------------------------------------------------- * */
    --foo-#{$key}-bg: #{$color};
    --foo-#{$key}-shadow: 0px 2px 8px #{$color};
  }

  @each $key, $color in $rgba-colors-opacity-colors {
    --foo-#{$key}-opacity-2: #{$color};
  }

  --foo-btn-shadow: 0px 10px 10px -4px #{$btn-shadow-color};
  --foo-btn-outline-shadow: 0px 19px 10px -14px #{$btn-outline-shadow-color};

  --foo-dialog-shadow: #{$dialog-shadow};

  --foo-loader-width: #{$loader-width};

  --foo-listing-row-height: #{$listing-row-height};
  --foo-container-min-height: #{$container-min-height};

  --foo-container-border: 1px solid #{$container-border-color};
  --foo-container-shadow: 0 1px 9px #{$container-shadow-color};

  --foo-box-border: 1px solid #{$box-border-color};
  --foo-box-shadow: 0px 2px 15px #{$box-shadow-color};

  ///**** Angular Material Variables ****///
  --mat-datepicker-calendar-date-selected-state-background-color: var(--foo-primary-color);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--foo-white-color);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--foo-item-tag-hover-color);
  --mat-expansion-header-hover-state-layer-color: var(--foo-list-row-hover-color);

  --mdc-fab-small-container-color: none;
  --mdc-filled-text-field-container-color: none;
  --mat-select-trigger-text-size: 12px;
  --mat-select-trigger-text-font: var(--foo-primary-font);

  --mat-select-trigger-text-line-height: 1;
  --mat-select-trigger-text-tracking: normal;

  --mat-form-field-container-text-line-height: 1.125;
  --mat-form-field-container-text-size: 12px;
  --mat-form-field-container-text-tracking: normal;
  --mat-option-selected-state-label-text-color: var(--foo-primary-color);
  --mat-option-label-text-color: var(--foo-dark-color);

  --mat-option-label-text-line-height: 1;
  --mat-option-label-text-size: 12px;
  --mat-option-label-text-tracking: normal;
  --mat-option-hover-state-layer-color: var(--foo-item-tag-hover-color);
  --mdc-filled-text-field-focus-active-indicator-height: 0px;
  --mdc-checkbox-unselected-icon-color:#0000008a;

  .mat-mdc-checkbox {
    --mdc-checkbox-selected-focus-state-layer-color: var(--foo-primary-color);
    --mdc-checkbox-selected-hover-state-layer-color: var(--foo-primary-color);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--foo-primary-color);
    --mdc-checkbox-selected-focus-icon-color: var(--foo-primary-color);
    --mdc-checkbox-selected-hover-icon-color: var(--foo-primary-color);
    --mdc-checkbox-selected-icon-color: var(--foo-primary-color);
    --mdc-checkbox-selected-pressed-icon-color: var(--foo-primary-color);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-state-layer-size: 0;
  }
}

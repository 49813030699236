
@import 'node_modules/swiper/swiper-bundle.min.css';
@import 'node_modules/simple-line-icons/scss/simple-line-icons.scss';


/*start project specific Variabls.sass*/
@import "styles/variables.scss";

//
///* Start Theme CSS */
@import 'foo_modules/foo-framework/src/lib/theme/theme.scss';
@import 'foo_modules/foo-framework/src/lib/theme/theme-ar.scss';
//
///* 06. Start Responsive CSS */
@import 'foo_modules/foo-framework/src/lib/theme/responsive.scss';
@import 'foo_modules/foo-framework/src/lib/theme/responsive-ar.scss';

/*start project specific Styles*/
/*Styles*/
@import "styles/proj";

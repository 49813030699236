/*
 * @PACKAGE foo-icons
 *
 * TABLE OF CONTENT:
 * ----------------
 * 1. Main Icon Class
 * 2. Main Icon mixin Class (Called in the next point)
 * 3. Loop theme colors, check $foo-theme-colors in (_foo-colors-map.scss)
 * 4. Add Classes in the loop to set dynmic icons classes of theme colors
 * 5. How to use: class="foo-icon foo-{icon_name}-{key}"
 *
 *
 * Main Classes:
 * ----------------
 * .foo-icon
 * .foo-{icon_name}-{key}
 * .
 *
 * Icons Classes Example:
 * ---------------------
 * .foo-menu-primary
 * .foo-menu-secondary
 * .foo-search-primary
 * .foo-search-secondary
 * .
 * .
 * .
 */

/* * ------------------- *
 * * 1. Main Icon Class  *
 * *-------------------- *
 */

.foo-icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;

  &.foo-stack {
    width: 20px;
    height: 20px;

    .foo-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  -webkit-mask-size: cover;
  mask-size: cover;

  &.foo-icon-active {
    background-color: $white-color;
  }
}
.foo-circle{
  border: 1px solid transparent;
  border-radius: 50%;
}
.foo-square{
  border: 1px solid transparent;
  border-radius: 2px;
}
/* * ------------------------ * *
 * * 2. Main Icon mixin Class * *
 * * ------------------------ * */
@mixin foo-icon($color, $icon) {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;

  @if $color != 'var(--foo-default-color)' {
    -webkit-mask-image: url(#{$icon});
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 100% 100%;
    -webkit-mask-size: cover;
    mask-image: url(#{$icon});
    mask-repeat: no-repeat;
    mask-position: 100% 100%;
    mask-size: cover;
    background: #{$color} !important;
  }
  @else {
    background: url(#{$icon}) no-repeat 100% 100% !important;
    background-size: cover !important;
  }
  filter: unset !important;
}


/* * ------------------------------------------------------------------ * *
 * * 3. Loop theme colors check $foo-theme-colors in (_foo-colors-map.scss) * *
 * * ------------------------------------------------------------------ * */
$foo-theme-colors: map-merge(
    (
      "default": null,
    ), $foo-theme-colors
);
@each $key, $color in $foo-theme-colors {
  /* * ---------------------------------------------------------------------------- * *
   * * 4. Add Icons Classes in the loop to set icons dynmic classes of theme colors * *
   * * ---------------------------------------------------------------------------- * */
  .foo-icon {
    &.foo-menu-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/menu_white.svg');
      width: 26px;
      height: 26px;
    }

    &.foo-pfm-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/pfm.svg');
    }

    &.foo-search-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/search-btn.svg');
    }

    &.foo-filter-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/filter.svg');
    }

    &.foo-back-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/back.svg');
      width: 29px;
      height: 28px;
    }

    &.foo-info-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/info.svg');
    }

    &.foo-wallet-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/wallet.svg');
    }

    &.foo-cards-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/cards.svg');
    }

    &.foo-microfinance-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/microfinance.svg');
    }

    &.foo-device-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/device.svg');
    }

    &.foo-close-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/close.svg');

      &.filter-close-button {
        position: absolute !important;
      }
    }

    &.foo-message-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/message.svg');
    }

    &.foo-add-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/add.svg');
    }

    &.foo-edit-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/edit.svg');
    }

    &.foo-delete-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/delete.svg');
    }

    &.foo-calendar-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/calendar.svg');
    }

    &.foo-eye-show-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/eye-show.svg');
    }

    &.foo-eye-hide-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/eye-hide.svg');
    }

    &.foo-bookmark-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/bookmark.svg');
    }

    &.foo-bookmark-hide-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/bookmark-hide.svg');
    }

    &.foo-empty-star-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/empty-star.svg');
    }

    &.foo-fill-star-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/fill-star.svg');
    }

    &.foo-half-star-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/half-star.svg');
    }

    &.foo-arrow-left-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/arrow_right.svg');
      transform: rotate(180deg)
    }

    &.foo-arrow-right-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/arrow_right.svg');
    }

    &.foo-arrow-up-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/arrow_right.svg');
      transform: rotate(-90deg);
    }

    &.foo-arrow-down-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/arrow_right.svg');
      transform: rotate(90deg);
    }

    &.foo-language-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/Language.svg');
      width: 100%;
      height: 100%;
    }

    &.foo-settings-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/settings.svg');
    }

    &.foo-download-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/download.svg');
    }

    &.foo-download-cloud-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/download-cloud.svg');
      width: 15px;
      height: 15px;
    }

    &.foo-customize-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/customize.svg');
    }

    &.foo-form-info-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/form-info.svg');
      height: 12px;
      width: 12px;
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-position: center;
      mask-position: center;
    }

    &.foo-people-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/people.svg');
    }

    &.foo-organization-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/organization.svg');
    }

    &.foo-android-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/android.svg');
      width: 21px;
    }

    &.foo-iphone-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/iphone.svg');
      width: 21px;
    }

    &.foo-secret-key-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/Secret-Key.svg');
    }

    &.foo-copy-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/copy.svg');
      width: 25px;
    }

    &.foo-accept-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/accept.svg');
      width: 16px;
      height: 16px;
    }

    &.foo-reject-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/reject.svg');
      width: 16px;
      height: 16px;
    }

    &.foo-more-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/more.svg');
    }

    &.foo-check-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/check.svg');
    }

    &.foo-logout-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/logout-white.svg');
      width: 22px;
    }

    &.foo-play-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/play.svg');
      width: 12px;
    }

    &.foo-time-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/time.svg');
    }

    &.foo-upload-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/upload.svg');
    }

    &.foo-arrow-rotate-left-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/arrow-rotate.svg');
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    &.foo-arrow-rotate-right-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/arrow-rotate.svg');
    }

    &.foo-circle-#{$key} {
      background: var(--foo-#{$key}-color);
      border: 1px solid var(--foo-#{$key}-color);
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }

    &.foo-visa-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/visa-icon.svg');
      width: 50px;
      height: 16px;
    }

    &.foo-mastercard-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/mastercard-icon.svg');
      width: 42px;
      height: 33px;
    }

    &.foo-branch-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/branch.svg');
    }

    &.foo-chart-nodata-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/chart-nodata.svg');
      width: 184px;
      height: 129px;
    }
    &.foo-qr-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/qr_code.svg');
    }

    &.foo-qr-empty-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/qr_code_empty.svg');
    }
    &.foo-resend-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/resend.svg');
    }

    &.foo-user-frame-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/user-frame.svg');
    }

    &.foo-file-outlined-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/file-outlined.svg');
    }

    &.foo-check-badge-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/check-badge.svg');
    }

    &.foo-document-invalid-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/document-invalid.svg');
    }

    &.foo-document-valid-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/document-valid.svg');
    }

    &.foo-dash-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/dash.svg');
    }
    &.foo-ban-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/Block-Unblock.svg');
    }

    &.foo-user-xmark-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/user-xmark.svg');
      width: 25px;
      height: 24px;
    }

    &.foo-building-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/building.svg');
    }

    &.foo-task-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/task.svg');
    }

    &.foo-market-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/market.svg');
    }

    &.foo-alert-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/alerts.svg');
    }

    &.foo-sliders-h-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/sliders-h.svg');
    }

    &.foo-circle-xmark-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/rejected.svg');
    }

    &.foo-dashboard-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/home.svg');
    }

    &.foo-accounts-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/accounts.svg');
    }

    &.foo-atms-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/atms.svg');
    }

    &.foo-team-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/team.svg');
    }

    &.foo-approvals-#{$key} {
      @include foo-icon(var(--foo-#{$key}-color), '/assets/img/approvals.svg');
    }
  }
}

.foo-icon-form-info-box {
  border-radius: 50% !important;
  box-shadow: 0px 6px 19px rgb(46 196 133 / 20%);
}

.foo-success-pass-check {
  background: url('/assets/img/step-approved.svg') center no-repeat;
  background-size: cover !important; 
  width: 15px;
  height: 15px;
}

.foo-failed-pass-check {
  background: url('/assets/img/rejected.svg') center no-repeat;
  background-size: cover !important; 
  width: 15px;
  height: 15px;
}
